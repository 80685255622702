import React from 'react';
var Sound = require('react-sound').default;


class AudioPlayComp extends React.Component {


    componentDidUpdate(prevProps) {

    }

    handleSongFinishedPlaying = () => {
        if (this.props.toggleAudio) {
            this.props.toggleAudio()
        }
    }

    render() {
        console.log('Render audio play', this.props.audioUrl)
        return (
            <Sound
                url={this.props.audioUrl || ''}
                playStatus={this.props.audioStatus}
                onFinishedPlaying={this.handleSongFinishedPlaying}
            />
        )
    }
}

export default AudioPlayComp;