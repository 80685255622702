import { Button, Input } from "antd";
import React, { Component } from "react";
import "./AccPane.css";
export default class AccPane extends Component {
  logout() {
    localStorage.removeItem("username");
    window.location.reload();
  }
  render() {
    const curPage = this.props.currentPage;
    const userName = localStorage.getItem("username") || "admin";
    return (
      <div className="acc-pane-container">
        {/* <div className="acc-pane-section">
          <Button shape="round">Theo dõi</Button>
          <h2
            style={{ textAlign: "center", color: "#0bf50b", fontWeight: "800" }}
          >
            NetMe
          </h2>
          <div class="acc-pane-info">{userName}</div>
        </div>

        <div className="acc-pane-section">
          <h2 style={{ textAlign: "center", color: "red", fontWeight: "800" }}>
            NetWeb
          </h2>
          <div class="acc-pane-info">
            <Input />
          </div>
        </div>

        <div className="acc-pane-section">
          <h2 style={{ textAlign: "center", color: "blue", fontWeight: "800" }}>
            GreatNet
          </h2>
          <div class="acc-pane-info">
            <Input />
          </div>
        </div> */}
        {/* 
        <div className="acc-pane-section">
          <div
            class="acc-pane-info"
            style={{ padding: "5px", display: "flex", flexDirection: "column" }}
          >
            <h4 style={{ textAlign: "left", fontWeight: "600" }}>Mới nhất</h4>
            <span style={{ textAlign: "left" }}>Trả lời nhiều nhất</span>
            <span style={{ textAlign: "left" }}>Nhiều like nhất</span>
            <span style={{ textAlign: "left" }}>Quan tâm nhất</span>
          </div>
        </div> */}

        <div className="acc-pane-section">
          <br />
          <Button shape="round" onClick={this.logout}>
            Đăng xuất
          </Button>
        </div>

        <div className="acc-pane-section">
          <div
            class="acc-pane-info"
            style={{ padding: "5px", display: "flex", flexDirection: "column" }}
          >
            <span
              style={{
                textAlign: "left",
                margin: "5px",
                fontWeight: curPage == "image" ? "700" : 400,
              }}
              onClick={() => this.props.changePage("image")}
            >
              Hình ảnh
            </span>
            <span
              style={{
                textAlign: "left",
                margin: "5px",
                fontWeight: curPage == "video" ? "700" : 400,
              }}
              onClick={() => this.props.changePage("video")}
            >
              Video
            </span>
            <span
              style={{
                textAlign: "left",
                margin: "5px",
                fontWeight: curPage == "post" ? "700" : 400,
              }}
              onClick={() => this.props.changePage("post")}
            >
              Bài đăng
            </span>
          </div>
        </div>
      </div>
    );
  }
}
