import React, { Component } from "react";
import { Button } from "antd";
import { UnorderedListOutlined } from "@ant-design/icons";

import "./BreadCumb.css";

export default class BreadCumb extends Component {
  render() {
    const curPage = this.props.currentPage;
    return (
      <div className="breadcumb-section">
        <span
          style={{
            textAlign: "left",
            margin: "5px",
            fontWeight: curPage == "folder" ? "700" : 400,
          }}
          onClick={() => this.props.changePage("folder")}
        >
          Thư mục
        </span>
        <span
          style={{
            textAlign: "left",
            margin: "5px",
            fontWeight: curPage == "image" ? "700" : 400,
          }}
          onClick={() => this.props.changePage("image")}
        >
          Hình ảnh
        </span>
        <span
          style={{
            textAlign: "left",
            margin: "5px",
            fontWeight: curPage == "video" ? "700" : 400,
          }}
          onClick={() => this.props.changePage("video")}
        >
          Video
        </span>
        <span
          style={{
            textAlign: "left",
            margin: "5px",
            fontWeight: curPage == "post" ? "700" : 400,
          }}
          onClick={() => this.props.changePage("post")}
        >
          Bài đăng
        </span>

        <Button
          type="primary"
          className="mobile-menu-btn"
          onClick={() => this.props.openDrawer()}
          icon={<UnorderedListOutlined />}
        />
      </div>
    );
  }
}
