export const LANGUAGES = [
  "vn",
  "en",
  "cn",
  "aa",
  "ab",
  "af",
  "be",
  "bg",
  "bh",
  "bi",
  "bm",
  "cr",
  "cs",
  "en",
  "eo",
  "es",
  "et",
  "ff",
  "fi",
  "fj",
  "fo",
  "fr",
  "fy",
  "ga",
  "ia",
  "id",
  "ie",
  "is",
  "it",
  "iu",
  "ja",
  "jv",
  "kl",
  "km",
  "mr",
  "ms",
  "ng",
  "nl",
  "nn",
  "no",
  "nr",
  "nv",
  "ny",
  "oc",
  "oj",
  "om",
  "or",
  "os",
  "pt",
  "qu",
  "rm",
  "rn",
  "ro",
  "ru",
  "sc",
  "sd",
  "sg",
  "sh",
  "si",
  "sk",
  "sl",
  "sm",
  "sn",
  "sr",
  "ss",
  "st",
  "su",
  "sv",
  "te",
  "tg",
  "th",
  "tk",
  "tr",
  "tw",
  "ty",
  "ug",
  "uk",
];
export const MAP_LANG = {
  vn: "tiếng Việt",
  en: "English",
  cn: "Chinese",
  aa: "Afar",
  ab: "Abkhazian",
  af: "Afrikaans",
  be: "Belarusian",
  bg: "Bulgarian",
  bh: "Bihari",
  bi: "Bislama",
  bm: "Bambara",
  cr: "Cree",
  cs: "Czech",
  eo: "Esperanto",
  es: "Spanish",
  et: "Estonian",
  ff: "Peul",
  fi: "Finnish",
  fj: "Fijian",
  fo: "Faroese",
  fr: "French",
  fy: "West Frisian",
  ga: "Irish",
  ia: "Interlingua",
  id: "Indonesian",
  ie: "Interlingue",
  is: "Icelandic",
  it: "Italian",
  iu: "Inuktitut",
  ja: "Japanese",
  jv: "Javanese",
  kl: "Greenlandic",
  km: "Cambodian",
  mr: "Marathi",
  ms: "Malay",
  ng: "Ndonga",
  nl: "Dutch",
  nn: "Norwegian Nynorsk",
  no: "Norwegian",
  nr: "South Ndebele",
  nv: "Navajo",
  ny: "Chichewa",
  oc: "Occitan",
  oj: "Ojibwa",
  om: "Oromo",
  or: "Oriya",
  os: "Ossetian",
  pt: "Portuguese",
  qu: "Quechua",
  rm: "Raeto Romance",
  rn: "Kirundi",
  ro: "Romanian",
  ru: "Russian",
  sc: "Sardinian",
  sd: "Sindhi",
  sg: "Sango",
  sh: "Serbo-Croatian",
  si: "Sinhalese",
  sk: "Slovak",
  sl: "Slovenian",
  sm: "Samoan",
  sn: "Shona",
  sr: "Serbian",
  ss: "Swati",
  st: "Southern Sotho",
  su: "Sundanese",
  sv: "Swedish",
  te: "Telugu",
  tg: "Tajik",
  th: "Thai",
  tk: "Turkmen",
  tr: "Turkish",
  tw: "Twi",
  ty: "Tahitian",
  ug: "Uyghur",
  uk: "Ukrainian",
};
