import React from 'react';
import { Recorder } from 'react-voice-recorder'
import 'react-voice-recorder/dist/index.css'


class RecordComp extends React.Component {

    state = {
        audioDetails: {
            url: this.props.audioUrl,
            blob: null,
            chunks: null,
            duration: {
                h: null,
                m: null,
                s: null,
            }
        }
    }

    componentDidUpdate(prevProps) {
        console.log('Audio change', this.props.audioUrl)
        if (this.props.audioUrl && this.props.audioUrl !== prevProps.audioUrl) {
            const audioDetails = { ...this.state.audioDetails };
            audioDetails.url = this.props.audioUrl;
            console.log('Apply audio URL', audioDetails);
            this.setState({ audioDetails });
        }
    }

    handleAudioStop(data) {
        console.log(data)
        this.setState({ audioDetails: data });
    }
    handleAudioUpload(file) {
        console.log(file);
        if (this.props.onUpload) {
            this.props.onUpload(file);
        }
    }
    handleRest() {
        const reset = {
            url: null,
            blob: null,
            chunks: null,
            duration: {
                h: null,
                m: null,
                s: null,
            }
        }
        this.setState({ audioDetails: reset });
    }

    handleOnChange(value, name) {
        console.log('On change', value, name)
    }

    render() {
        console.log('Audio change', this.state.audioDetails)
        return (
            <Recorder
                record={true}
                title={"Ghi âm phụ đề"}
                audioURL={this.state.audioDetails.url}
                showUIAudio={true}
                handleAudioStop={data => this.handleAudioStop(data)}
                handleOnChange={(value) => this.handleOnChange(value, 'firstname')}
                handleAudioUpload={data => this.handleAudioUpload(data)}
                handleRest={() => this.handleRest()}
                startRecording={() => console.log('Start')}
            />
        )
    }
}

export default RecordComp;