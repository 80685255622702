import React, { Component } from 'react'
import { Input, Typography, Button, Select } from 'antd';
import { withRouter } from "react-router-dom";

import './LoginPage.css'
import { getAccountlist } from '../Service/Redux/service';

const defaultUser = {
    username: 'dinhvanthuan',
    password: '123456a'
}
const { Title, Paragraph, Text } = Typography;
const { Option } = Select;
class LoginPage extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            username: '',
            password: '',
            accountList: []
        }
        let username = localStorage.getItem('username');
        if (username){
            this.props.history.push('/upload')
        }
    }

    componentDidMount() {
        getAccountlist((data)=>{
            this.setState({
                accountList:data
            })
        })
    }

    checkLogin=()=> {
        const account = this.state.accountList.filter(acc=>acc.username == this.state.username)[0];
        if (account) {
            if (account.password == this.state.password) {
                localStorage.setItem('username',account.username)
                this.props.history.push('/upload')
            }
        }
    }

    onLogin = () => {
     this.checkLogin();
    }

    render() {
        return (
            <div className='login-page'>
                <Input.Search
                    placeholder="Tìm kiếm"
                    onSearch={value => this.searchForAddress(value)}
                    style={{ width: '75%' }}
                />
                <div className='login-page-section'>
                    <div className="login-section">
                        <Title level={3}>GREATNET</Title>
                        <Input placeholder="Tên người dùng" value={this.state.username} onChange={(ev) => this.setState({ username: ev.target.value })} />
                        <Input placeholder="Password" value={this.state.password} onChange={(ev) => this.setState({ password: ev.target.value })} />
                        <Button onClick={this.onLogin}>Enter</Button>
                    </div>
                    <div className='payment-section'>
                        <img className="visa-logo" src="https://cdn.iconscout.com/icon/free/png-512/visa-3-226460.png"></img>
                        <div style={{ textAlign: 'center' }}>
                            Mua dịch vụ cho hệ thống của bạn : 250 USD/năm
                        </div>
                        <Button>Mua</Button>
                        <div style={{ textAlign: 'center', fontWeight: 'bold' }}>
                            Dịch vụ bao gồm:
                        </div>
                        <div style={{ textAlign: 'center', color: 'blue' }}>
                            Mua domain
                        </div>
                        <div style={{ textAlign: 'center' }}>
                            Greatnet@netweb.app
                        </div>
                    </div>
                </div>
                <div className='login-page-section'>
                    <Select defaultValue="dataweb" style={{ width: 200, position: 'absolute', right: '50px' }}>
                        <Option value="dataweb">Data Web</Option>
                        <Option value="netweb">Netweb</Option>
                        <Option value="greatsun">GreatSun</Option>
                        <Option value="loginnet">Login Net</Option>
                        <Option value="domainnet">Domain Net</Option>
                    </Select>
                </div>
                <div className='login-page-section'>
                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', width: '100%', margin: '10px' }}>
                        <div style={{ textAlign: 'center', color: 'blue' }}>
                            Tài Khoản GREATSUN
                        </div>
                        <div className="login-form-item">
                            <div style={{ width: '10%' }}>Mã số:</div>
                            <Input />
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', width: '100%', margin: '10px' }} >
                            <div style={{ display: 'flex', flexDirection: 'column', width: '40%' }} >
                                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', width: '100%', height: '20%', marginBottom: '10px' }}>
                                    <div style={{ width: '20%' }}>CCV: </div>
                                    <Input />
                                </div>
                                <Button>Đăng nhập</Button>
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', width: '50%', height: '200px', background: 'blue', marginLeft: '10px' }}>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }


}

export default withRouter(LoginPage);