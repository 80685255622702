import React from 'react';
import { Tabs } from 'antd';
import VideoView from './videoView';
import VideoSubTool from './videoSubTool';
const { TabPane } = Tabs;

const VideoContainer = ({ videoUrl, videoName }) => {
    console.log(videoUrl)
    return (
        <div>
            <Tabs defaultActiveKey="1">
                <TabPane tab="Xem video" key="1">
                    <VideoView videoUrl={videoUrl} videoName={videoName} />
                </TabPane>
                <TabPane tab="Dịch thuật" key="2">
                    <VideoSubTool videoUrl={videoUrl} videoName={videoName} />
                </TabPane>
            </Tabs>
        </div >
    )
}

export default VideoContainer;