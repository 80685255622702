import React, { Component } from "react";
import { Spin } from "antd";
export const Loading = ({isLoading}) => {
  return (
    isLoading && (
      <div
        style={{
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
          position: "fixed",
          background: "#00000059",
          zIndex: 1000,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Spin />
      </div>
    )
  );
};
