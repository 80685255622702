import React from "react";
import ReactPlayer from "react-player";
import LazyLoad from "react-lazyload";
import { render } from "react-dom";
import {
  Input,
  TimePicker,
  Button,
  Select,
  Popconfirm,
  notification,
  message,
  Tooltip,
} from "antd";
import {
  DeleteOutlined,
  CaretRightOutlined,
  AudioOutlined,
} from "@ant-design/icons";
import "./videoView.css";
import {
  saveVideoSub,
  getVideoSub,
  formatVideoName,
  uploadAudio,
  getAudioList,
  getFileUrl,
  parseSRT,
} from "../Service/Redux/service";
import RecordComp from "./recordingComp";
import { MAP_LANG, LANGUAGES } from "../constant";
import Sound from "react-sound";
import AudioPlayComp from "./audioPlayComp";
import { Loading } from "../loading";

const moment = require("moment");
const defaultTime = "00:00:00";

const { Option } = Select;

class VideoSubTool extends React.Component {
  state = {
    currentTime: { startTime: defaultTime, endTime: defaultTime },
    fileName: this.props.videoName,
    currentContent: "",
    subData: null,
    currentLang: "vn",
    translateLang: "none",
    originalLang: "vn",
    subList: [],
    transSubList: [],
    visibleRecordTool: [],
    audioList: [],
    audioStatusList: [],
    currentAudioStatus: "STOPPED",
    isLoadingApp: false,
  };

  setLoading = (isLoadingApp) => {
    this.setState({ isLoadingApp });
  };

  setSubData = (subData) => {
    console.log(subData);
    if (subData) {
      this.setLoading(true);
      const subList = subData[this.state.currentLang].data;
      const visibleRecordTool = subList.map((item) => false);
      const audioStatusList = subList.map((item) => "STOPPED");
      this.setState(
        { subData, subList, visibleRecordTool, audioStatusList },
        () => {
          this.setLoading(false);
        }
      );
    }
  };

  componentDidMount() {
    console.log("Mount");
    const fileName = formatVideoName(this.state.fileName);
    getVideoSub(fileName, this.setSubData);

    const audioRef = this.generateAudioName("");
    getAudioList(audioRef, this.setAudioList);
  }

  toggleAudioStt = (index) => {
    let list = [...this.state.audioStatusList];
    list = list.map((item, i) => {
      if (i !== index) {
        return "STOPPED";
      }
      return item;
    });
    let stt = list[index];
    if (stt === "STOPPED") {
      stt = "PLAYING";
    } else {
      stt = "STOPPED";
    }
    list[index] = stt;
    console.log("Start play", index, list);
    this.setState({ audioStatusList: list });
  };

  setAudioUrl = (name, url) => {
    let list = [...this.state.audioList];
    list = list.map((item) => {
      if (item.name === name) {
        item.url = url;
      }
      return item;
    });
    console.log("Set audio list", list);
    this.setState({ audioList: list });
  };

  setAudioList = (list) => {
    this.setState({ audioList: list });
    list.map((item) => {
      getFileUrl(item.location, (url) => this.setAudioUrl(item.name, url));
    });
  };

  ref = (player) => {
    this.player = player;
  };

  onVideoPause = () => {
    console.log(this.player);
    if (this.player) {
      const secs = this.player.getCurrentTime();
      const formatted = moment.utc(secs * 1000).format("HH:mm:ss");
      console.log(formatted);
      this.setState({
        currentTime: { startTime: formatted, endTime: formatted },
      });
    }
  };

  onVideoProgress = () => {};

  onSubInputChange = (content, index) => {
    if (index === -1) {
      this.setState({ currentContent: content });
      return;
    }
    const list = [...this.state.subList];
    list[index] = { ...list[index], content };
    this.setLoading(true);
    this.setState({ subList: list }, () => {
      this.setLoading(false);
    });
  };

  onTransSubInputChange = (content, index) => {
    if (index === -1) {
      this.setState({ currentContent: content });
      return;
    }
    const list = [...this.state.transSubList];
    list[index] = { ...list[index], content };
    this.setState({ transSubList: list });
  };

  onSubtimeChange = (value, index, isStartTime = true) => {
    const list = [...this.state.subList];
    console.log("Set time", value, index);
    if (index >= 0) {
      const time = {
        ...list[index].time,
        [isStartTime ? "startTime" : "endTime"]: value,
      };
      list[index] = { ...list[index], time };
      this.setState({ subList: list });
      return;
    }
    const time = {
      ...this.state.currentTime,
      [isStartTime ? "startTime" : "endTime"]: value,
    };
    console.log("Set current time", time);
    this.setState({ currentTime: time });
  };

  addSub = () => {
    const list = [
      ...this.state.subList,
      { time: this.state.currentTime, content: this.state.currentContent },
    ];
    const currentTime = {
      startTime: this.state.currentTime.endTime,
      endTime: this.state.currentTime.endTime,
    };
    this.setState({
      subList: list,
      currentTime,
      currentContent: "",
    });
  };

  saveSub = () => {
    const name = formatVideoName(this.state.fileName);
    console.log(this.state.subData);
    let data = [];
    if (this.state.currentContent.trim()) {
      data = [
        ...this.state.subList,
        { time: this.state.currentTime, content: this.state.currentContent },
      ];
    } else {
      data = [...this.state.subList];
    }

    let editedList = {
      [this.state.currentLang]: { data },
    };

    // if (this.state.transSubList.length > 0) {
    //     editedList = {
    //         ...editedList,
    //         [this.state.translateLang]: { data: [...this.state.transSubList] }
    //     }
    // }

    saveVideoSub(name, { ...this.state.subData, ...editedList });
    this.setLoading(true);
    const newData = { ...this.state.subData, ...editedList };
    this.setState({ subData: { ...newData } }, () => {
      this.setLoading(false);
    });
    // message.error('Server đang bảo trì')
  };

  confirmDeleteSub = (index) => {
    const list = [...this.state.subList];
    list.splice(index, 1);
    this.setState({ subList: list });
  };

  generateAudioName = (index) => {
    if (index !== "") {
      return (
        this.state.fileName +
        "/" +
        this.state.currentLang +
        "/" +
        index +
        ".ogg"
      );
    }
    return this.state.fileName + "/" + this.state.currentLang + "/" + index;
  };

  onUploadAudio = (index, file) => {
    const ref = this.generateAudioName(index);
    uploadAudio(file, ref, () => {
      message.info("Save ghi âm thành công");
      this.saveSub();
    });
  };

  toggleRecordTool = (index) => {
    console.log("Toogle record", index);
    let visibleList = [...this.state.visibleRecordTool];
    visibleList = visibleList.map((item, i) => {
      console.log("Toogle record", item, i);
      if (i !== index) {
        return false;
      }
      return !item;
    });
    this.setState({ visibleRecordTool: visibleList });
  };

  getSuggestSub = (currentTime, index) => {
    const subList =
      this.state.subData && this.state.subData[this.state.originalLang]
        ? this.state.subData[this.state.originalLang].data
        : [];
    // console.log("Sugest sub frm", subList);
    if (!subList) {
      return;
    }
    // const content = subList.find((sub, index) => {
    //   const timeStart = moment.duration(sub.time.startTime).asSeconds();
    //   const timeAfter = moment.duration(sub.time.endTime).asSeconds();
    //   const timeValue = moment.duration(currentTime.startTime).asSeconds();
    //   if (timeValue >= timeStart && timeValue <= timeAfter) {
    //     console.log("Get sugest sub", sub);
    //     return true;
    //   }
    //   return false;
    // });
    const content = subList[index] ? subList[index] : null;
    if (content) {
      return content.content;
    }
    return null;
  };

  renderSubEditor = (time, content, index) => {
    // console.log(
    //   this.state.visibleRecordTool,
    //   index,
    //   this.state.transSubList[index]
    // );
    // console.log(this.state);
    if (this.state.translateLang === "none") {
      return (
        <LazyLoad height={50}>
          <Input.Group className="sub-input" compact>
            <div
              className="ico-btn record-ico"
              onClick={() => this.toggleRecordTool(index)}
            >
              <AudioOutlined />
            </div>
            <div className="ico-btn play-ico">
              <CaretRightOutlined onClick={() => this.toggleAudioStt(index)} />
            </div>
            <TimePicker
              value={moment(time.startTime, "HH:mm:ss")}
              size="large"
              onChange={(ev) =>
                this.onSubtimeChange(ev.format("HH:mm:ss"), index, true)
              }
            />
            <TimePicker
              value={moment(time.endTime, "HH:mm:ss")}
              size="large"
              onChange={(ev) =>
                this.onSubtimeChange(ev.format("HH:mm:ss"), index, false)
              }
            />
            <Input
              style={{ flex: 1, height: "40px" }}
              value={content}
              onChange={(ev) => this.onSubInputChange(ev.target.value, index)}
            />
            <Popconfirm
              placement="topLeft"
              title={"Xóa sub"}
              onConfirm={() => this.confirmDeleteSub(index)}
              okText="Xóa"
              cancelText="Hủy"
              className="delete-sub-btn"
            >
              <DeleteOutlined />
            </Popconfirm>
          </Input.Group>
          {/* {this.state.visibleRecordTool[index] && (
            <RecordComp
              onUpload={(file) => this.onUploadAudio(index, file)}
              audioUrl={
                this.state.audioList[index]
                  ? this.state.audioList[index].url
                  : null
              }
            />
          )}
          {this.state.audioList && (
            <AudioPlayComp
              audioUrl={
                this.state.audioList[index]
                  ? this.state.audioList[index].url
                  : null
              }
              audioStatus={this.state.audioStatusList[index]}
              toggleAudio={() => this.toggleAudioStt(index)}
            />
          )} */}
        </LazyLoad>
      );
    }
    const suggestedSub = this.getSuggestSub(time, index);
    // console.log("render with", suggestedSub);
    return (
      // <div>
      //    <Input.Group className="sub-input" compact>
      //      <div className="ico-btn record-ico" onClick={() => this.toggleRecordTool(index)}>
      //             <AudioOutlined />
      //         </div>
      //         <div className="ico-btn play-ico">
      //             <CaretRightOutlined />
      //         </div>
      //         <TimePicker value={moment(time, 'HH:mm:ss')} size="large" onChange={(ev) => this.onSubtimeChange(ev.format('HH:mm:ss'), index)} disabled={true} />
      //         <Input style={{ flex: 1, height: '40px' }} value={content} onChange={ev => this.onSubInputChange(ev.target.value, index)} disabled={true} />
      //         <Popconfirm placement="topLeft" title={'Xóa sub'} onConfirm={() => this.confirmDeleteSub(index)} okText="Xóa" cancelText="Hủy" className="delete-sub-btn">
      //             <DeleteOutlined />
      //         </Popconfirm>
      //     </Input.Group>
      //     <Input.Group className="sub-input" compact>
      //         <div className="ico-btn play-ico">
      //             <CaretRightOutlined />
      //         </div>
      //         <TimePicker value={moment(time, 'HH:mm:ss')} size="large" onChange={(ev) => this.onSubtimeChange(ev.format('HH:mm:ss'), index)} disabled={true} />
      //         <Input style={{ flex: 1, height: '40px' }} value={this.state.transSubList[index].content} onChange={ev => this.onTransSubInputChange(ev.target.value, index)} />
      //     </Input.Group>
      //     {this.state.visibleRecordTool[index] &&
      //         <RecordComp />
      //     }
      //</div>
      <LazyLoad height={50}>
        <Input.Group className="sub-input" compact>
          <div
            className="ico-btn record-ico"
            onClick={() => this.toggleRecordTool(index)}
          >
            <AudioOutlined />
          </div>
          <div className="ico-btn play-ico">
            <CaretRightOutlined onClick={() => this.toggleAudioStt(index)} />
          </div>
          <TimePicker
            value={moment(time.startTime, "HH:mm:ss")}
            size="large"
            onChange={(ev) =>
              this.onSubtimeChange(ev.format("HH:mm:ss"), index, true)
            }
          />
          <TimePicker
            value={moment(time.endTime, "HH:mm:ss")}
            size="large"
            onChange={(ev) =>
              this.onSubtimeChange(ev.format("HH:mm:ss"), index, false)
            }
          />
          {suggestedSub ? (
            <div style={{ flex: 1 }}>
              <Input
                style={{ flex: 1, height: "40px" }}
                value={suggestedSub}
                disabled={true}
              />
              <Input
                style={{ flex: 1, height: "40px" }}
                value={content}
                onChange={(ev) => this.onSubInputChange(ev.target.value, index)}
              />
            </div>
          ) : (
            <Input
              style={{ flex: 1, height: "40px" }}
              value={content}
              onChange={(ev) => this.onSubInputChange(ev.target.value, index)}
            />
          )}
          <Popconfirm
            placement="topLeft"
            title={"Xóa sub"}
            onConfirm={() => this.confirmDeleteSub(index)}
            okText="Xóa"
            cancelText="Hủy"
            className="delete-sub-btn"
          >
            <DeleteOutlined />
          </Popconfirm>
        </Input.Group>
        {/* {this.state.visibleRecordTool[index] && (
          <RecordComp
            onUpload={(file) => this.onUploadAudio(index, file)}
            audioUrl={
              this.state.audioList[index]
                ? this.state.audioList[index].url
                : null
            }
          />
        )} */}
      </LazyLoad>
    );
  };

  renderSubList = () => {
    const currentTime = this.state.currentTime;
    let newTime =
      this.state.subList && this.state.subList.length > 0
        ? {
            startTime: this.state.subList[this.state.subList.length - 1].time
              .endTime,
            endTime: this.state.subList[this.state.subList.length - 1].time
              .endTime,
          }
        : currentTime;
    newTime = currentTime || newTime;

    return (
      <div className="video-sub-list">
        {this.state.subList.map((sub, index) =>
          this.renderSubEditor(sub.time, sub.content, index)
        )}
        {this.renderSubEditor(newTime, this.state.currentContent, -1)}
        <Button type="ghost" onClick={this.addSub}>
          Thêm
        </Button>
        <Button type="primary" onClick={this.saveSub}>
          Lưu
        </Button>
      </div>
    );
  };

  changeSubLang = (lang) => {
    if (this.state.translateLang !== "none") {
      this.setState(
        {
          originalLang: lang,
          currentLang: lang,
          subList,
        },
        () => {
          const audioRef = this.generateAudioName("");
          getAudioList(audioRef, this.setAudioList);
        }
      );
      return;
    }
    const subList =
      this.state.subData && this.state.subData[lang]
        ? this.state.subData[lang].data
        : [];
    this.setState({
      originalLang: lang,
      subList,
    });
  };

  changeTransLang = (lang) => {
    if (lang !== "none") {
      const subData = { ...this.state.subData };
      if (subData[this.state.originalLang]) {
        subData[lang] = subData[lang] || { data: [] };
        subData[lang].data = subData[this.state.originalLang].data.map(
          (item, index) => {
            return {
              ...item,
              content:
                subData[lang] && subData[lang].data[index]
                  ? subData[lang].data[index].content
                  : "",
            };
          }
        );
      }
      const subList = subData && subData[lang].data;
      this.setState(
        {
          translateLang: lang,
          currentLang: lang,
          subData,
          subList,
        },
        () => {
          const audioRef = this.generateAudioName("");
          getAudioList(audioRef, this.setAudioList);
        }
      );
      return;
    }
    const subList =
      this.state.subData && this.state.subData[this.state.originalLang]
        ? this.state.subData[this.state.originalLang].data
        : [];
    this.setState(
      {
        translateLang: "none",
        currentLang: this.state.originalLang,
        subList,
      },
      () => {
        const audioRef = this.generateAudioName("");
        getAudioList(audioRef, this.setAudioList);
      }
    );
  };

  showFile = async (e) => {
    this.setLoading(true);
    e.preventDefault();
    const reader = new FileReader();
    reader.onload = async (e) => {
      const text = e.target.result;
      const parsedData = JSON.parse(parseSRT(text));
      this.setState({ subList: parsedData }, () => {
        this.setLoading(false);
      });
    };
    reader.readAsText(e.target.files[0]);
  };

  render() {
    console.log("Render");
    if (this.player) {
      console.log(this.player.getCurrentTime());
    }
    return (
      <div>
        <Loading isLoading={this.state.isLoadingApp} />
        <ReactPlayer
          url={this.props.videoUrl}
          controls={true}
          ref={this.ref}
          onPause={this.onVideoPause}
          onProgress={this.onVideoProgress}
        />
        <Select
          value={this.state.originalLang}
          onSelect={(ev) => this.changeSubLang(ev)}
          style={{ width: "50%" }}
        >
          {LANGUAGES.map((l) => (
            <Option value={l}>{MAP_LANG[l]}</Option>
          ))}
        </Select>
        <Select
          value={this.state.translateLang}
          onSelect={(ev) => this.changeTransLang(ev)}
          style={{ width: "50%" }}
        >
          <Option value="none">Không chọn</Option>
          {LANGUAGES.map(
            (l) =>
              l !== this.state.originalLang && (
                <Option value={l}>{MAP_LANG[l]}</Option>
              )
          )}
        </Select>
        {this.renderSubList()}
        <input type="file" onChange={(e) => this.showFile(e)} />
      </div>
    );
  }
}

export default VideoSubTool;
