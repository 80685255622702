import React, { Component } from "react";
import { Button, Modal, Input, Alert } from "antd";
import {
  submitMomoPayment,
  getUserStorageInfo,
  updateUserStorageInfo,
  updateUserBalance,
} from "../Service/Redux/service";
import { v4 as uuidv4 } from "uuid";
import Base64 from "crypto-js/enc-base64";
var CryptoJS = require("crypto-js");
var converter = require("byte-converter").converterBase10;

export default class DepositComp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isDepositModalVisible: false,
      depositValue: 0,
      isBuyStorageModalVisible: false,
      error: null,
    };
  }

  componentDidMount() {
    getUserStorageInfo((info) => this.setUserStorageInfo(info));
  }

  setUserStorageInfo = (info) => {
    console.log(info);
    this.setState({ storageInfo: info });
  };

  submitMomoPayInfo = () => {
    const amount = parseInt(this.state.depositValue);
    if (amount < 0) {
      return;
    }
    const partnerCode = "MOMOALQ920210326";
    const accessKey = "MZ3pJ13uBlcmd6T8";
    const secretKey = "sHASNs2Z5UiGxMKEo1DrLofcGzZSQYPd";
    const requestId = uuidv4();
    const username = localStorage.getItem("username") || "admin";
    const returnUrl = "https://greatbeta-cfa05.web.app/";
    const message = `partnerCode=${partnerCode}&accessKey=${accessKey}&requestId=${requestId}&amount=${
      this.state.depositValue
    }&orderId=${requestId}&orderInfo=SDK team.&returnUrl=${returnUrl}&notifyUrl=${returnUrl}&extraData=${username}`;

    const signature = CryptoJS.HmacSHA256(message, secretKey).toString();
    console.log(message, signature);
    const info = {
      accessKey: accessKey,
      partnerCode: partnerCode,
      requestType: "captureMoMoWallet",
      notifyUrl: returnUrl,
      returnUrl: returnUrl,
      orderId: requestId,
      amount: this.state.depositValue,
      orderInfo: "SDK team.",
      requestId: requestId,
      extraData: username,
      signature: signature,
    };

    submitMomoPayment(info)
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        if (data.errorCode == 0) {
          window.location.href = data.payUrl;
        }
      });
  };

  renderDepositModal = () => {
    return (
      <Modal
        title="Nap tiền"
        visible={this.state.isDepositModalVisible}
        onOk={this.submitMomoPayInfo}
        onCancel={() => this.setState({ isDepositModalVisible: false })}
      >
        <Input
          value={this.state.depositValue}
          onChange={(ev) => this.setState({ depositValue: ev.target.value })}
        />
      </Modal>
    );
  };

  buyStorage = (amount, money) => {
    if (this.props.amount < money) {
      this.setState({
        error: "Bạn không đủ số dư tài khoản, xin mời nạp thêm",
      });
      return;
    }
    this.setState({ error: null });
    const newStorageInfo = {
      total: Number(this.state.storageInfo.total) + amount,
      remain: Number(this.state.storageInfo.remain) + amount,
    };
    updateUserStorageInfo(newStorageInfo);
    updateUserBalance(-money);
  };

  renderBuyStorageModal = () => {
    const itemList = [
      { amount: 10, money: 20000 },
      { amount: 20, money: 30000 },
      { amount: 50, money: 90000 },
    ];
    return (
      <Modal
        title="Mua dung lượng"
        visible={this.state.isBuyStorageModalVisible}
        onOk={() => this.setState({ isBuyStorageModalVisible: false })}
        onCancel={() => this.setState({ isBuyStorageModalVisible: false })}
      >
        {this.state.error && <Alert message={this.state.error} type="error" />}
        {itemList.map((item) => (
          <div style={{ display: "flex", margin: " 10px" }}>
            <span style={{ flex: 1 }}>
              {item.amount}GB: {new Intl.NumberFormat().format(item.money)} GC
            </span>
            <Button
              type="primary"
              onClick={() =>
                this.buyStorage(converter(item.amount, "GB", "MB"), item.money)
              }
            >
              Mua
            </Button>
          </div>
        ))}
      </Modal>
    );
  };

  render() {
    return (
      <div className="page-intro">
        {this.renderDepositModal()}
        {this.renderBuyStorageModal()}
        <span>GreatCoin: {this.props.amount}</span>
        {this.state.storageInfo && (
          <div>
            <span>
              Tổng dung lượng:{" "}
              {converter(this.state.storageInfo.total, "MB", "GB")} GB
            </span>
            <br />
            <span>
              Dung lượng còn lại:{" "}
              {converter(this.state.storageInfo.remain, "MB", "GB")} GB
            </span>
          </div>
        )}

        <br />
        <Button onClick={() => this.setState({ isDepositModalVisible: true })}>
          Nạp tiền
        </Button>
        <Button
          onClick={() => this.setState({ isBuyStorageModalVisible: true })}
        >
          Mua dung lượng
        </Button>
      </div>
    );
  }
}
