import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import LoginPage from './LoginPage/LoginPage';
import * as serviceWorker from './serviceWorker';
import 'antd/dist/antd.css';

///redux
import thunkMiddleware from 'redux-thunk';
import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
import { Provider } from 'react-redux';
import createHistory from 'history/createBrowserHistory';

import { ConnectedRouter, routerReducer, routerMiddleware, push } from 'react-router-redux';
import { BrowserRouter as Router, Route, Link } from 'react-router-dom';

import { rootReducer } from './Service/Redux/reducer';
import UploadPage from './Upload/UploadPage';
import MainPage from './MainPage/MainPage';

const history = createHistory();
const middleware = routerMiddleware(history);
const store = createStore(
    rootReducer,
    compose(applyMiddleware(middleware, thunkMiddleware), window.devToolsExtension ? window.devToolsExtension() : (f) => f)
);

ReactDOM.render(
    <Provider store={store}>
        <ConnectedRouter history={history}>
            <div>
                <Router>
                    <div>
                        {/* <Route path='/' component={LoginPage} /> */}
                        <Route path="/login/" component={LoginPage} />
                        <Route path="/upload/" component={MainPage} />
                        <Route path="/" component={MainPage} />
                    </div>
                </Router>
            </div>
        </ConnectedRouter>
    </Provider>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
