
export const SIGNOUT="SIGNOUT";



export const SET_USER_INFO_AFTER_LOGIN="SET_USER_INFO_AFTER_LOGIN";

export const SET_RESULT_LIST="SET_RESULT_LIST";
export const SET_USER_PAGE_INFO="SET_USER_PAGE_INFO"

export const SET_USER_PAGE_CAT="SET_USER_PAGE_CAT"
export const SET_CURRENT_POST="SET_CURRENT_POST"

export const SET_FOLLOW_PAGE_LIST="SET_FOLLOW_PAGE_LIST"
export const SET_LIKED_POST_LIST="SET_LIKED_POST_LIST"

export const SET_CMT_LIST="SET_CMT_LIST"
export const SET_FRIEND_REQ_LIST="SET_FRIEND_REQ_LIST"
export const SET_SHARE_LIST="SET_SHARE_LIST"
export const SET_CHAT_CONTENT="SET_CHAT_CONTENT"
export const CLEAR_CHAT_CONTENT="CLEAR_CHAT_CONTENT"
////////////// LOGIN SIGNUP action //////////////////////

//LOGIN



// if success then set userinfo to store


export function setUserInfoAfterLogin(userInfo) {
        return {
            type: SET_USER_INFO_AFTER_LOGIN,
            userInfo
        };
    } 
    
    

//SIGNOUT

export function signout(){
        return {
            type:SIGNOUT
        }
    }
    

export function setResultList(data){
    return{
                type:SET_RESULT_LIST,
                data
    }
}

export function setUserPageInfo(data){
    return{
        type:SET_USER_PAGE_INFO,
        data
    }
}

export function setUserPageCat(data){
    return{
                type: SET_USER_PAGE_CAT,
                data
    }
}


export function setCurrentPost(data){
    return{
        type: SET_CURRENT_POST,
        data
    }
}


export function setFollowPageList(list){
    return{
        type:SET_FOLLOW_PAGE_LIST,
        list
    }
}

export function setLikedPostList(list){
    return{
        type:SET_LIKED_POST_LIST,
        list
    }
}

export function setCmtList(list){
    return{
        type: SET_CMT_LIST,
        list
    }
}

export function setFriendReqList(list){
    return {
        type: SET_FRIEND_REQ_LIST,
        list
    }
}

export function setShareList(list){
    return{
        type:SET_SHARE_LIST,
        list
    }
}

export function setChatContent(list){
    return{
        type:SET_CHAT_CONTENT,
    
        list
    }
}

export function clearChatContent(){
    return{
            type:CLEAR_CHAT_CONTENT
    }
}