import firebase_init, {
  auth,
  Authprovider,
  firestore,
  storageRef,
  database,
} from "../firebase.js";

import {
  setUserInfoAfterLogin,
  setResultList,
  signout,
  setUserPageInfo,
  setUserPageCat,
  setCurrentPost,
  setFollowPageList,
  setLikedPostList,
  setCmtList,
  setFriendReqList,
  setShareList,
  setChatContent,
  clearChatContent,
} from "./action";
import { getConfirmLocale } from "antd/lib/modal/locale";
import { uuid } from "uuidv4";
import { LANGUAGES } from "../../constant.js";

var moment = require("moment");
const AccountData_Table = "AccountData";

export function loginGG() {
  return (dispatch) => {
    auth.signInWithPopup(Authprovider).then((result) => {
      const user = result.user;
      dispatch(setUserInfoAfterLogin(user));

      firestore
        .collection(AccountData_Table)
        .doc(user.uid)
        .get()
        .then((doc) => {
          if (doc.exists) {
            console.log(doc);
          }
        });
    });
  };
}

export function logOut() {
  return (dispatch) => {
    firebase_init
      .auth()
      .signOut()
      .then(() => {
        dispatch(signout());
      });
  };
}

export function checkLogged_and_Login_automatically() {
  return (dispatch) => {
    auth.onAuthStateChanged((user) => {
      if (user) {
        dispatch(setUserInfoAfterLogin(user));

        getFollowPage(dispatch, user.uid);
        getLikedPost(dispatch, user.uid);
        getFriendRequestList(dispatch, user.uid);
        writeUserData(user);
      }
    });
  };
}

function writeUserData(user) {
  console.log("Write user info", user);
  database
    .ref("AccountDetail")
    .child(user.uid)
    .set({
      displayName: user.providerData[0].displayName,
      photoURL: user.providerData[0].photoURL,
    });
}

export function getAccountlist(cb) {
  const userName = "admin";
  database.ref(`Account`).once("value", (snapshot) => {
    const accountList = snapshot.val();
    console.log(accountList);
    if (cb) {
      cb(accountList);
    }
    //   dispatch(setResultList(data));
  });
}

export function uploadAddressImage(
  userInfo,
  address,
  desc,
  detailt,
  downloadList,
  tag,
  subTags,
  sideTags
) {
  return (dispatch) => {
    var newPostKey = database.ref("Address").push().key;

    database
      .ref("Address")
      .child(newPostKey)
      .set({
        UID: userInfo.uid,
        username: userInfo.displayName,
        address,
        desc,
        detailt,
        likeCount: 0,
        downloadList,
        created_date: moment().format("YYYY-MM-DD HH:mm:ss"),
        tag,
        subTags,
        sideTags,
      });

    database
      .ref("PostTranslated/" + newPostKey + "/")
      .child("default")
      .set({
        UID: userInfo.uid,
        id: newPostKey,
        username: userInfo.displayName,
        address,
        desc,
        likeCount: 0,
        detailt,
        downloadList,
        created_date: moment().format("YYYY-MM-DD HH:mm:ss"),
        tag,
        subTags,
      });
  };
}

export function uploadPostTranslation(
  userInfo,
  id,
  address,
  desc,
  detailt,
  downloadList,
  tag,
  subTags,
  sideTags,
  language
) {
  return (dispatch) => {
    database
      .ref("PostTranslated/" + id + "/")
      .child(language)
      .set({
        UID: userInfo.uid,
        username: userInfo.displayName,
        id,
        address,
        desc,
        detailt,
        downloadList,
        created_date: moment().format("YYYY-MM-DD HH:mm:ss"),
        tag,
        subTags,
        sideTags,
      });
  };
}

export function searchAddress(address) {
  return (dispatch) => {
    console.log(address);
    database.ref("Address").on("value", (snapshot) => {
      let data = [];
      snapshot.forEach(function(childSnapshot) {
        var childKey = childSnapshot.key;
        var childData = childSnapshot.val();
        if (childData.address.includes(address)) {
          data.push({ id: childKey, ...childData });
        }
        // ...
      });
      dispatch(setResultList(data));
    });
  };
}

export function getFolderList(callback) {
  const userName = localStorage.getItem("username") || "admin";
  database.ref(`FolderList/${userName}`).once("value", (snapshot) => {
    let data = [];
    const folderList = snapshot.val();
    callback(folderList);
    //   dispatch(setResultList(data));
  });
}

export function setFolderList(data) {
  const userName = localStorage.getItem("username") || "admin";
  database.ref(`FolderList/${userName}`).set(data);
}

export function formatVideoName(name = "") {
  let newName = name.replace(".", "");
  newName = newName.replace(".MOV", "");
  newName = newName.replace(" ", "");
  return newName;
}

export function saveVideoSub(videoName, data) {
  const userName = localStorage.getItem("username") || "admin";
  database.ref(`video/${userName}/${videoName}/subData`).set({
    ...data,
  });
}

export function getVideoSub(videoName, callback) {
  const userName = localStorage.getItem("username") || "admin";
  database
    .ref(`video/${userName}/${videoName}/subData`)
    .once("value", (snapshot) => {
      console.log(snapshot.val());
      callback(snapshot.val());
    });
}

export function saveVideoData(videoName, data) {
  const userName = localStorage.getItem("username") || "admin";
  database.ref(`video/${userName}/${videoName}/videoData`).set({
    ...data,
  });
}

export function deleteVideoData(videoName, ref) {
  const userName = localStorage.getItem("username") || "admin";
  database.ref(`video/${userName}/${videoName}`).remove();
  deleteVideo(ref);
}

export function getVideoData(videoName, callback) {
  const userName = localStorage.getItem("username") || "admin";
  database
    .ref(`video/${userName}/${videoName}/videoData`)
    .once("value", (snapshot) => {
      console.log(snapshot.val());
      callback(snapshot.val());
    });
}

export function saveToRedBar(ref, title, dataType) {
  const userName = localStorage.getItem("username") || "admin";
  database.ref(`redbar/${userName}/${ref}/`).set({
    key: ref,
    title,
    type: dataType,
  });
}

export function getRedBarData(callback) {
  const userName = localStorage.getItem("username") || "admin";
  database.ref(`redbar/${userName}/`).once("value", (snapshot) => {
    let data = [];
    snapshot.forEach(function(childSnapshot) {
      var childKey = childSnapshot.key;
      var childData = childSnapshot.val();
      data.push({ id: childKey, ...childData });
    });
    callback(data);
  });
}

export function getAlbumData(ref, callback) {
  const userName = localStorage.getItem("username") || "admin";
  database.ref(`albums/${userName}/${ref}/`).once("value", (snapshot) => {
    let data = [];
    snapshot.forEach(function(childSnapshot) {
      var childKey = childSnapshot.key;
      var childData = childSnapshot.val();
      data.push({ id: childKey, ...childData });
    });
    callback(data);
  });
}
export function getImageAlbumData(ref, callback) {
  const userName = localStorage.getItem("username") || "admin";
  database.ref(`albums/image/${userName}/${ref}/`).once("value", (snapshot) => {
    let data = [];
    snapshot.forEach(function(childSnapshot) {
      var childKey = childSnapshot.key;
      var childData = childSnapshot.val();
      data.push({ id: childKey, ...childData });
    });
    callback(data);
  });
}

export function createAlbum(albumName, folderRef, cb) {
  const userName = localStorage.getItem("username") || "admin";
  let id = uuid();
  const album = {
    key: id,
    title: albumName,
  };
  database.ref(`albums/${userName}/${folderRef}/${id}/`).set(album);
  if (cb) {
    cb(album);
  }
}

export function createImageAlbum(albumName, folderRef, cb) {
  const userName = localStorage.getItem("username") || "admin";
  let id = uuid();
  const album = {
    key: id,
    title: albumName,
  };
  database.ref(`albums/image/${userName}/${folderRef}/${id}/`).set(album);
  if (cb) {
    cb(album);
  }
}

export function getVideoList(ref, callback) {
  console.log("ref", ref);
  const userName = localStorage.getItem("username") || "admin";
  let listRef = storageRef.child(`videos/${userName}/${ref}`);

  // Find all the prefixes and items.
  listRef
    .listAll()
    .then(function(res) {
      let list = [];
      res.items.forEach(function(itemRef) {
        // All the items under listRef.
        console.log("itemRef", itemRef);
        list.push({
          location: itemRef.location.path,
          name: itemRef.name,
        });
      });
      callback(list);
    })
    .catch(function(error) {
      // Uh-oh, an error occurred!
    });
}

export function getImageList(ref, callback) {
  console.log("ref", ref);
  const userName = localStorage.getItem("username") || "admin";
  let listRef = storageRef.child(`images/${userName}/${ref}`);

  // Find all the prefixes and items.
  listRef
    .listAll()
    .then(function(res) {
      let list = [];
      res.items.forEach(function(itemRef) {
        // All the items under listRef.
        console.log("itemRef", itemRef);
        list.push({
          location: itemRef.location.path,
          name: itemRef.name,
        });
      });
      callback(list);
    })
    .catch(function(error) {
      // Uh-oh, an error occurred!
    });
}

export function getAudioList(ref, callback) {
  console.log("ref", ref);
  const userName = localStorage.getItem("username") || "admin";
  let listRef = storageRef.child(`audio/${userName}/${ref}`);

  // Find all the prefixes and items.
  listRef
    .listAll()
    .then(function(res) {
      let list = [];
      res.items.forEach(function(itemRef) {
        // All the items under listRef.
        console.log("itemRef", itemRef);
        list.push({
          location: itemRef.location.path,
          name: itemRef.name,
        });
      });
      callback(list);
    })
    .catch(function(error) {
      // Uh-oh, an error occurred!
    });
}

export function uploadImage(file, ref, callback) {
  const userName = localStorage.getItem("username") || "admin";
  let imageRef = storageRef.child(`images/${userName}/${ref}`);
  imageRef.put(file).then(function(snapshot) {
    console.log("Uploaded a blob or file!");
    if (callback) {
      callback();
    }
  });
}

export function uploadVideo(file, ref, callback) {
  const userName = localStorage.getItem("username") || "admin";
  let videoRef = storageRef.child(`videos/${userName}/${ref}`);
  videoRef.put(file).then(function(snapshot) {
    console.log("Uploaded a blob or file!");
    if (callback) {
      callback();
    }
  });
}

export function deleteVideo(ref) {
  const userName = localStorage.getItem("username") || "admin";
  let videoRef = storageRef.child(`videos/${userName}/${ref}`);
  videoRef.delete().then(function(snapshot) {});
}

export function deleteFile(type, ref) {
  const userName = localStorage.getItem("username") || "admin";
  let fileRef = storageRef.child(`${type}/${userName}/${ref}`);
  fileRef.delete().then(function(snapshot) {});
}

export function uploadAudio(file, ref, callback) {
  const userName = localStorage.getItem("username") || "admin";
  let audioRef = storageRef.child(`audio/${userName}/${ref}`);
  audioRef.put(file).then(function(snapshot) {
    console.log("Uploaded a blob or file!");
    if (callback) {
      callback();
    }
  });
}

export function deletePost(ref, key) {
  const userName = localStorage.getItem("username") || "admin";
  database.ref(`Posts/${userName}/${ref}/${key}`).remove();
}

export function getPostList(ref = "", callback) {
  const userName = localStorage.getItem("username") || "admin";
  database.ref(`Posts/${userName}/${ref}`).on("value", (snapshot) => {
    const list = [];
    snapshot.forEach(function(childSnapshot) {
      var childKey = childSnapshot.key;
      var childData = childSnapshot.val();
      console.log(childData);
      const dataList = [];
      LANGUAGES.map((lang) => {
        const data = childData[lang];
        if (data) {
          dataList.push({
            key: childKey,
            title: data.title,
            des: data.des,
            content: data.post,
            lang,
          });
        }
      });
      list.push({
        key: childKey,
        title: dataList[0].title,
        des: dataList[0].des,
        list: dataList,
      });
    });

    console.log("Get list", list);
    if (callback) {
      callback(list);
    }
  });
}

export function uploadPostToFirebase(ref = "", lang, title, des, post) {
  let id = uuid();
  const userName = localStorage.getItem("username") || "admin";
  database
    .ref(`Posts/${userName}/${ref}/${id}/${lang}`)
    .set({ title, des, post });
}

export function editPostToFirebase(ref = "", id, lang, title, des, post) {
  const userName = localStorage.getItem("username") || "admin";
  database
    .ref(`Posts/${userName}/${ref}/${id}/${lang}`)
    .set({ title, des, post });
}

export function getFileUrl(location, callback?) {
  // Create a reference with an initial file path and name
  var storage = firebase_init.storage();
  var pathReference = storage.ref(location);
  pathReference.getDownloadURL().then(function(url) {
    console.log(url);
    if (callback) {
      callback(url);
    }
  });
}

export function createPage(pageName, userInfo) {
  return (dispatch) => {
    database
      .ref("Pages")
      .child(userInfo.uid)
      .set({
        UID: userInfo.uid,
        username: userInfo.displayName,
        pageName,
      });
  };
}

export function createCategory(oldCat, mainCatName, catName, userInfo) {
  return (dispatch) => {
    if (mainCatName === "Web") return;
    if (oldCat) {
      ///
      if (oldCat.catList[mainCatName])
        oldCat.catList[mainCatName].push(catName);
      else oldCat.catList[mainCatName] = [catName];

      ///
      database
        .ref("Categories")
        .child(userInfo.uid)
        .set({
          UID: userInfo.uid,
          username: userInfo.displayName,
          catList: oldCat.catList,
        });
    } else {
      let newCat = {};
      newCat[mainCatName] = [catName];
      database
        .ref("Categories")
        .child(userInfo.uid)
        .set({
          UID: userInfo.uid,
          username: userInfo.displayName,
          catList: newCat,
        });
    }
  };
}

export function getPageInfo(uid) {
  return (dispatch) => {
    console.log("getPage");
    database
      .ref("Pages")
      .child(uid)
      .once("value", (snapshot) => {
        dispatch(setUserPageInfo(snapshot.val()));
      });
  };
}

export function getCatUserPage(uid) {
  return (dispatch) => {
    console.log("getCat");
    database
      .ref("Categories")
      .child(uid)
      .on("value", (snapshot) => {
        dispatch(setUserPageCat(snapshot.val()));
      });
  };
}

export function setCurrentPostView(post) {
  console.log(post);
  return (dispatch) => {
    console.log("set post");
    dispatch(setCurrentPost(post));
  };
}

export function getPostTranslation(id, callback) {
  database
    .ref("PostTranslated/" + id + "/")
    .once("value")
    .then((snapshot) => {
      let translateData = [];
      if (snapshot.exists()) {
        snapshot.forEach(function(childSnapshot) {
          var childKey = childSnapshot.key;
          var childData = childSnapshot.val();
          translateData.push({ language: childKey, data: childData });
          //
        });
      }
      callback(translateData);
    });
}

export function followPage(userInfo, pageName, pageUID) {
  database
    .ref("PageFollowed/" + userInfo.uid + "/")
    .child(pageName)
    .set({
      pageName,
      pageUID,
    });
}

export function getFollowPage(dispatch, uid) {
  console.log("uid", uid);
  database
    .ref("PageFollowed/" + uid + "/")
    .once("value")
    .then((snapshot) => {
      let pageList = [];
      if (snapshot.exists()) {
        snapshot.forEach(function(childSnapshot) {
          var childKey = childSnapshot.key;
          var childData = childSnapshot.val();
          pageList.push({ name: childKey, detail: childData });
          //
        });
      }
      dispatch(setFollowPageList(pageList));
    });
}

export function getLikedPost(dispatch, uid) {
  database.ref("PostLiked/" + uid + "/").on("value", (snapshot) => {
    let likedList = [];
    if (snapshot.exists()) {
      snapshot.forEach(function(childSnapshot) {
        var childKey = childSnapshot.key;
        var childData = childSnapshot.val();
        likedList.push({ id: childKey, liked: childData.liked });
      });
    }
    dispatch(setLikedPostList(likedList));
  });
}

export function likePost(userInfo, id, oldLikeCount) {
  console.log("like");
  database
    .ref("PostLiked/" + userInfo.uid + "/")
    .child(id)
    .set({
      liked: true,
    });
  if (oldLikeCount)
    database
      .ref("Address")
      .child(id)
      .update({ likeCount: oldLikeCount + 1 });
  else {
    database
      .ref("Address")
      .child(id)
      .update({ likeCount: 1 });
  }
}

export function unlikePost(userInfo, id, oldLikeCount) {
  console.log("unlike");
  database
    .ref("PostLiked/" + userInfo.uid + "/")
    .child(id)
    .update({
      liked: false,
    });
  if (oldLikeCount)
    database
      .ref("Address")
      .child(id)
      .update({ likeCount: oldLikeCount - 1 });
}

export function getCommentList(id) {
  return (dispatch) => {
    database
      .ref("Comments")
      .child(id)
      .on("value", (snapshot) => {
        let cmtList = [];
        if (snapshot.exists()) {
          snapshot.forEach(function(childSnapshot) {
            var childKey = childSnapshot.key;
            var childData = childSnapshot.val();
            cmtList.push({
              id: childKey,
              content: childData.content,
              created_date: childData.created_date,
              username: childData.username,
            });
          });
        }
        dispatch(setCmtList(cmtList));
      });
  };
}

export function submitCmt(userInfo, id, content) {
  var newPostKey = database.ref("Comments/" + id + "/").push().key;

  database
    .ref("Comments/" + id + "/")
    .child(newPostKey)
    .set({
      UID: userInfo.uid,
      username: userInfo.displayName,
      content,
      created_date: moment().format("YYYY-MM-DD HH:mm:ss"),
    });
}

export function addFriend(userInfo, friendUID) {
  database
    .ref("Account/" + friendUID + "/")
    .child(userInfo.uid)
    .set({
      status: "requesting",
      username: userInfo.displayName,
      photoURL: userInfo.photoURL,
    });
}

export function acceptFriend(userInfo, friendUID, username, photoURL) {
  database
    .ref("Account/" + userInfo.uid + "/")
    .child(friendUID)
    .set({
      status: "friend",
      username,
      photoURL,
    });
  database
    .ref("Account/" + friendUID + "/")
    .child(userInfo.uid)
    .set({
      status: "friend",
      username: userInfo.displayName,
      photoURL: userInfo.photoURL,
    });
}

export function denytFriend(userInfo, friendUID) {
  database
    .ref("Account/" + userInfo.uid + "/")
    .child(friendUID)
    .set({
      status: "deny",
    });
  database
    .ref("Account/" + friendUID + "/")
    .child(userInfo.uid)
    .set({
      status: "deny",
    });
}

export function blockFriend(userInfo, friendUID) {
  database
    .ref("Account/" + userInfo.uid + "/")
    .child(friendUID)
    .set({
      status: "blocked",
    });
}

export function unblockFriend(userInfo, friendUID) {
  database
    .ref("Account/" + userInfo.uid + "/")
    .child(friendUID)
    .set({
      status: "deny",
    });
}

export function getFriendRequestList(dispatch, uid) {
  database
    .ref("Account")
    .child(uid)
    .on("value", (snapshot) => {
      let friendReqList = [];
      if (snapshot.exists()) {
        snapshot.forEach(function(childSnapshot) {
          var childKey = childSnapshot.key;
          var childData = childSnapshot.val();
          friendReqList.push({ id: childKey, ...childData });
        });
      }
      dispatch(setFriendReqList(friendReqList));
    });
}

export function sharePost(userInfo, friendList, post) {
  let n = friendList.length;

  console.log("SHARE");
  console.log(friendList);
  console.log(post);
  let downloadList = [];
  if (post.downloadList) downloadList = post.downloadList;
  for (let i = 0; i < n; i++) {
    database
      .ref("Netshare/" + friendList[i].id + "/")
      .child(post.id)
      .set({
        UID: post.UID,
        username: post.username,
        address: post.address,
        desc: post.desc,
        detailt: post.detailt,
        likeCount: post.likeCount,
        downloadList,
        created_date: post.created_date,
        tag: post.tag,
        subTags: post.subTags,
        sideTags: post.sideTags,
        shareUID: userInfo.uid,
        shareDisplayName: userInfo.displayName,
      });
  }
}

export function getSharePostList(userInfo) {
  return (dispatch) => {
    console.log("GET SHARE");
    database
      .ref("Netshare")
      .child(userInfo.uid)
      .on("value", (snapshot) => {
        let postList = [];
        if (snapshot.exists()) {
          snapshot.forEach(function(childSnapshot) {
            var childKey = childSnapshot.key;
            var childData = childSnapshot.val();
            postList.push({ id: childKey, ...childData });
          });
        }
        dispatch(setShareList(postList));
      });
  };
}

export function getUserProfile(uid, callback) {
  database
    .ref("AccountDetail")
    .child(uid)
    .once("value", (snapshot) => {
      if (snapshot.exists()) {
        let profile = snapshot.val();
        callback(profile);
      }
    });
}

export function getFriendList(uid, callback) {
  database
    .ref("Account")
    .child(uid)
    .once("value", (snapshot) => {
      let friendList = [];
      if (snapshot.exists()) {
        snapshot.forEach(function(childSnapshot) {
          var childKey = childSnapshot.key;
          var childData = childSnapshot.val();
          if (childData.status === "friend")
            friendList.push({ id: childKey, ...childData });
        });
      }
      callback(friendList);
    });
}

export function submitChatText(userInfo, receiverInfo, content) {
  var newKey = database
    .ref("Chat")
    .child(userInfo.uid)
    .child(receiverInfo.id)
    .push().key;
  database
    .ref("Chat")
    .child(userInfo.uid)
    .child(receiverInfo.id)
    .child(newKey)
    .set({
      uid: userInfo.uid,
      username: userInfo.displayName,
      message: content,
      id: 0,
    });
  database
    .ref("Chat")
    .child(receiverInfo.id)
    .child(userInfo.uid)
    .child(newKey)
    .set({
      uid: userInfo.uid,
      username: userInfo.displayName,
      message: content,
      id: 1,
    });
}

export function getChatContent(userInfo, receiverInfo) {
  return (dispatch) => {
    database
      .ref("Chat")
      .child(userInfo.uid)
      .child(receiverInfo.id)
      .on("value", (snapshot) => {
        if (snapshot.exists) {
          let list = [];
          snapshot.forEach((childSnapshot) => {
            var childData = childSnapshot.val();
            list.push(childData);
          });
          dispatch(setChatContent(list));
        }
        console.log(snapshot.val());
      });
  };
}

export function clearChat() {
  return (dispatch) => {
    dispatch(clearChatContent());
  };
}

export function getVideoCover(file, seekTo = 0.0) {
  console.log("getting video cover for file: ", file);
  return new Promise((resolve, reject) => {
    // load the file to a video player
    const videoPlayer = document.createElement("video");
    videoPlayer.setAttribute("src", file);
    videoPlayer.setAttribute("crossorigin", "anonymous");
    videoPlayer.load();
    videoPlayer.addEventListener("error", (ex) => {
      reject("error when loading video file", ex);
    });
    // load metadata of the video to get video duration and dimensions
    videoPlayer.addEventListener("loadedmetadata", () => {
      // seek to user defined timestamp (in seconds) if possible
      if (videoPlayer.duration < seekTo) {
        reject("video is too short.");
        return;
      }
      // delay seeking or else 'seeked' event won't fire on Safari
      setTimeout(() => {
        videoPlayer.currentTime = seekTo;
      }, 200);
      // extract video thumbnail once seeking is complete
      videoPlayer.addEventListener("seeked", () => {
        console.log("video is now paused at %ss.", seekTo);
        // define a canvas to have the same dimension as the video
        const canvas = document.createElement("canvas");
        canvas.width = videoPlayer.videoWidth;
        canvas.height = videoPlayer.videoHeight;

        canvas
          .getContext("2d")
          .drawImage(videoPlayer, 0, 0, canvas.width, canvas.height);

        // draw the video frame to canvas
        const ctx = canvas.getContext("2d");

        ctx.drawImage(videoPlayer, 0, 0, canvas.width, canvas.height);
        // return the canvas image as a blob
        ctx.canvas.toBlob(
          (blob) => {
            resolve(blob);
          },
          "image/jpeg",
          0.75 /* quality */
        );
      });
    });
  });
}

export function parseSRT(content) {
  let pattern = /(\d+)\n([\d:,]+)\s+-{2}\>\s+([\d:,]+)\n([\s\S]*?(?=\n{2}|$))/gm;
  let _regExp;
  _regExp = new RegExp(pattern);
  let parse = function(f) {
    if (typeof f != "string") throw "Sorry, Parser accept string only.";

    let result = [];
    if (f == null) return null;

    f = f.replace(/\r\n|\r|\n/g, "\n");
    let matches = pattern.exec(f);
    while (matches !== null) {
      result.push(toLineObj(matches));
      matches = pattern.exec(f);
    }
    return result;
  };
  let toLineObj = function(group) {
    return {
      time: {
        endTime: group[2],
        startTime: group[3],
      },
      content: group[4],
    };
  };

  const result = parse(content);
  return JSON.stringify(
    result.filter(function(q) {
      return q != null;
    })
  );
}

export function submitMomoPayment(data) {
  let formData = new FormData();
  formData.append("accessKey", data.accessKey);
  formData.append("partnerCode", data.partnerCode);
  formData.append("requestType", data.requestType);
  formData.append("notifyUrl", data.notifyUrl);
  formData.append("returnUrl", data.returnUrl);
  formData.append("orderId", data.orderId);
  formData.append("amount", data.amount);
  formData.append("orderInfo", data.orderInfo);
  formData.append("requestId", data.requestId);
  formData.append("extraData", data.extraData);
  formData.append("signature", data.signature);

  return fetch("https://test-payment.momo.vn/gw_payment/transactionProcessor", {
    body: JSON.stringify(data),
    method: "post",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
  });
}

export function checkPayRequestValid(requestId, callback) {
  database
    .ref("paymentId")
    .child("list")
    .once("value", (snapshot) => {
      const obj = snapshot.val();
      let requestIds = Object.keys(obj).map((k) => obj[k]);
      console.log("Requetlist", requestIds, snapshot.val());
      if (requestIds.some((id) => id === requestId)) {
        callback(requestIds, false);
        return;
      }
      requestIds.push(requestId);
      callback(requestIds, true);
    });
}

export function updatePaymentIDList(list) {
  database.ref("paymentId").set({
    list,
  });
}

export function updateUserBalance(amount) {
  const userName = localStorage.getItem("username") || "admin";
  database
    .ref(`Balance`)
    .child(userName)
    .once("value", (snapshot) => {
      const obj = snapshot.val();
      console.log(obj);
      let newAmount = 0;
      if (obj) {
        newAmount = parseInt(obj) + parseInt(amount);
      } else {
        newAmount = parseInt(amount);
      }

      database
        .ref("Balance")
        .child(userName)
        .set(newAmount);
    });
}

export function getUserBalance(callback) {
  const userName = localStorage.getItem("username") || "admin";
  database
    .ref(`Balance`)
    .child(userName)
    .on("value", (snapshot) => {
      const obj = snapshot.val();
      if (obj) {
        callback(obj);
        return;
      }
      callback(0);
    });
}



export function getUserStorageInfo(callback) {
  const userName = localStorage.getItem("username") || "admin";
  database
    .ref(`Storage`)
    .child(userName)
    .on("value", (snapshot) => {
      const obj = snapshot.val();
      if (obj) {
        callback(obj);
        return;
      }
      updateUserStorageInfo({total: '100000', remain: '100000'})
    });
}


export function updateUserStorageInfo(info) {
  const userName = localStorage.getItem("username") || "admin";
      database
        .ref("Storage")
        .child(userName)
        .set(info);
}