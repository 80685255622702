import React, { Component } from "react";
import { FolderFilled } from "@ant-design/icons";
import { Button, Input, Popover } from "antd";

import "./album.css";
import {
  getAlbumData,
  createAlbum,
  getImageAlbumData,
  createImageAlbum,
} from "../Service/Redux/service";

export class Album extends Component {
  constructor(props) {
    super(props);
    const defaultAlbum = {
      key: this.props.folderRef,
      title: "Mặc định",
    };
    this.state = {
      defaultAlbum,
      albumData: [defaultAlbum],
      selectedAlbumKey: this.props.selectedAlbumKey,
      createAlbumPopupVisible: false,
      creatingAlbumName: "",
    };
  }

  componentDidMount() {
    if (this.props.folderRef) {
      if (this.props.type == "image") {
        getImageAlbumData(this.props.folderRef, this.setAlbumData.bind(this));
        return;
      }
      getAlbumData(this.props.folderRef, this.setAlbumData.bind(this));
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.folderRef !== this.props.folderRef) {
      getAlbumData(this.props.folderRef, this.setAlbumData.bind(this));
    }
  }

  createNewAlBum = () => {
    if (this.props.type == "image") {
      createImageAlbum(
        this.state.creatingAlbumName,
        this.props.folderRef,
        (album) => {
          const albumData = [...this.state.albumData, album];
          this.setState({
            albumData,
            createAlbumPopupVisible: false,
          });
        }
      );
      return;
    }
    createAlbum(this.state.creatingAlbumName, this.props.folderRef, (album) => {
      const albumData = [...this.state.albumData, album];
      this.setState({
        albumData,
        createAlbumPopupVisible: false,
      });
    });
  };

  setAlbumData = (albumData) => {
    console.log("Album", albumData);
    this.setState({
      albumData: [this.state.defaultAlbum, ...(albumData ? albumData : [])],
    });
  };

  selectAlbum = (key) => {
    this.setState({
      selectedAlbumKey: key,
    });
    if (this.props.onSelectAlbum) {
      console.log("Select", key);
      this.props.onSelectAlbum(key);
    }
  };

  handleCreatePopupVisibleChange = (createAlbumPopupVisible) => {
    this.setState({ createAlbumPopupVisible });
  };

  renderCreatePopupContent = () => {
    return (
      <div>
        <Input
          value={this.state.creatingAlbumName}
          onChange={(e) => this.setState({ creatingAlbumName: e.target.value })}
        />
        <Button onClick={this.createNewAlBum}>Tạo</Button>
      </div>
    );
  };
  renderCreateAlbumPopover = () => {
    return (
      <Popover
        content={this.renderCreatePopupContent()}
        title="Tạo"
        trigger="click"
        visible={this.state.createAlbumPopupVisible}
        onVisibleChange={this.handleCreatePopupVisibleChange}
      >
        <Button
          style={{ marginBottom: "10px" }}
          onClick={() => this.setState({ createAlbumPopupVisible: true })}
        >
          Tạo Album
        </Button>
      </Popover>
    );
  };

  renderAlbumList = () => {
    return (
      <div className="album-list">
        {this.state.albumData.map((album) => {
          return (
            <div
              className={
                this.state.selectedAlbumKey === album.key
                  ? "album-item selected"
                  : "album-item"
              }
              key={album.key}
              onClick={() => this.selectAlbum(album.key)}
            >
              <FolderFilled className="album-ico" />
              <div>{album.title}</div>
            </div>
          );
        })}
      </div>
    );
  };

  render() {
    return (
      <div className="album-container">
        {this.renderCreateAlbumPopover()}
        {this.renderAlbumList()}
      </div>
    );
  }
}
