import React from "react";
import ReactPlayer from "react-player";
import { Input, Button, Select } from "antd";
import {
  formatVideoName,
  getVideoSub,
  getFileUrl,
  getAudioList,
  getVideoData,
  saveVideoData,
  getVideoCover,
  uploadImage,
} from "../Service/Redux/service";
import AudioPlayComp from "./audioPlayComp";
import { MAP_LANG, LANGUAGES } from "../constant";
const moment = require("moment");

const { Option } = Select;
class VideoView extends React.Component {
  state = {
    fileName: this.props.videoName,
    subData: null,
    subList: [],
    currentLang: "vn",
    currentTime: "00:00:00",
    currentTime: -1,
    currentAudioStt: "STOPPED",
    videoName: "",
    audioList: [],
    audioStatusList: [],
  };

  generateAudioName = (index) => {
    if (index !== "") {
      return (
        this.state.fileName +
        "/" +
        this.state.currentLang +
        "/" +
        index +
        ".ogg"
      );
    }
    return this.state.fileName + "/" + this.state.currentLang + "/" + index;
  };

  setAudioUrl = (name, url) => {
    let list = [...this.state.audioList];
    list = list.map((item) => {
      if (item.name === name) {
        item.url = url;
      }
      return item;
    });
    console.log("Set audio list", list);
    this.setState({ audioList: list });
  };

  setAudioList = (list) => {
    this.setState({ audioList: list });
    list.map((item) => {
      getFileUrl(item.location, (url) => this.setAudioUrl(item.name, url));
    });
  };

  setSubData = (subData) => {
    console.log(subData);
    if (subData) {
      const audioStatusList = subData[this.state.currentLang].data.map(
        (item) => "STOPPED"
      );
      this.setState({
        subData,
        subList: subData[this.state.currentLang].data,
        audioStatusList,
      });
    }
  };

  setVideoData = (data) => {
    if (data) {
      const { videoName, videoDesc } = data;
      if (videoName) {
        this.setState({ videoName });
      }
      if (videoDesc) {
        this.setState({ videoDesc });
      }
    }
  };

  componentDidMount() {
    console.log("Mount");
    const fileName = formatVideoName(this.state.fileName);
    getVideoSub(fileName, this.setSubData);
    this.setState({ videoName: fileName });

    const audioRef = this.generateAudioName("");
    getAudioList(audioRef, this.setAudioList);

    getVideoData(fileName, this.setVideoData);

    this.getVideoThumb();
  }

  async getVideoThumb() {
    try {
      // get the frame at 1.5 seconds of the video file
      const cover = await getVideoCover(this.props.videoUrl, 1.5);
      // print out the result image blob
      console.log("COver", cover);
      uploadImage(
        cover,
        "video-thumbnail/" + formatVideoName(this.state.fileName)
      );
    } catch (ex) {
      console.log("ERROR: ", ex);
    }
  }

  toggleAudioStt = (index) => {
    let list = [...this.state.audioStatusList];
    list = list.map((item, i) => {
      if (i !== index) {
        return "STOPPED";
      }
      return item;
    });
    let stt = list[index];
    if (stt === "STOPPED") {
      stt = "PLAYING";
    } else {
      stt = "STOPPED";
    }
    list[index] = stt;
    console.log("Start play", index, list);
    this.setState({ audioStatusList: list });
  };

  renderSub = () => {
    if (!this.state.subList) {
      return;
    }
    console.log(this.state.subList);
    const subList = this.state.subList;
    const content = subList.find((sub, index) => {
      console.log(index);
      const timeStart = moment.duration(sub.time.startTime).asSeconds();
      const timeAfter = moment.duration(sub.time.endTime).asSeconds();
      const currentTime = moment.duration(this.state.currentTime).asSeconds();
      if (currentTime >= timeStart && currentTime <= timeAfter) {
        console.log(sub);
        return true;
      }
      return false;
    });
    console.log(content);
    return <div className="sub-view">{content ? content.content : ""}</div>;
  };

  onVideoProgress = (ev) => {
    console.log(ev);
    const secs = ev.playedSeconds;
    const formatted = moment.utc(secs * 1000).format("HH:mm:ss");
    console.log(formatted);

    const subList = this.state.subList;
    let currentIndex = 0;
    subList.find((sub, index) => {
      console.log(index);
      const timeStart = moment.duration(sub.time.startTime).asSeconds();
      const timeAfter = moment.duration(sub.time.endTime).asSeconds();
      const currentTime = moment.duration(formatted).asSeconds();
      if (currentTime >= timeStart && currentTime <= timeAfter) {
        currentIndex = index;
        return true;
      }
      return false;
    });

    let stt = this.state.currentAudioStt;
    if (!this.state.audioList[currentIndex]) {
      stt = "STOPPED";
    }
    this.setState({
      currentTime: formatted,
      currentIndex,
      currentAudioStt: stt,
    });
  };

  onChangeName = (ev) => {
    this.setState({ videoName: ev.target.value });
  };

  submitVideoData = () => {
    const fileName = formatVideoName(this.state.fileName);
    saveVideoData(fileName, { videoName: this.state.videoName });
  };

  changeSubLang(lang) {
    const subList =
    this.state.subData && this.state.subData[lang]
      ? this.state.subData[lang].data
      : [];
    this.setState({
      currentLang: lang,
      subList,
    });
  }

  render() {
    return (
      <div>
        <div style={{ display: "flex", flexDirection: "row" }}>
          <Input value={this.state.videoName} onChange={this.onChangeName} />
          <Button onClick={this.submitVideoData}>Lưu</Button>
        </div>
        <ReactPlayer
          url={this.props.videoUrl}
          controls={true}
          width={'100%'}
          onProgress={this.onVideoProgress}
          onPause={() => this.setState({ currentAudioStt: "STOPPED" })}
          onPlay={() => this.setState({ currentAudioStt: "PLAYING" })}
        />{" "}
        <Select
          value={this.state.currentLang}
          onSelect={(ev) => this.changeSubLang(ev)}
          style={{ width: "50%" }}
        >
          {LANGUAGES.map((l) => (
            <Option value={l}>{MAP_LANG[l]}</Option>
          ))}
        </Select>
        {this.renderSub()}
        <AudioPlayComp
          audioUrl={
            this.state.audioList[this.state.currentIndex]
              ? this.state.audioList[this.state.currentIndex].url
              : null
          }
          audioStatus={this.state.currentAudioStt}
        />
      </div>
    );
  }
}

export default VideoView;
