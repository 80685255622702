import classNames from 'classnames';
import React, { Component } from 'react';
import { getRedBarData } from '../Service/Redux/service';

import './redbar.css';
export default class RedBar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            selectedKey: '0-0',
        };
    }

    setRedBarData = (data) => {
        console.log('Redbar', data);
        this.setState({ data });
    };

    onSelect = (item) => {
        console.log('Select', item);
        this.setState({
            selectedKey: item.key,
        });
        this.props.onSelect(item);
    };

    componentDidMount() {
        getRedBarData(this.setRedBarData);
    }

    render() {
        return (
            <div className="flex items-center gap-5 p-2 text-white bg-blue-500 redbar-container">
                {this.state.data.map((item) => (
                    <div
                        className={classNames(
                            'redbar-item',
                            this.state.selectedKey === item.key && 'rounded-full bg-blue-300 p-2 font-semibold text-black border border-black'
                        )}
                        onClick={() => this.onSelect(item)}
                    >
                        {item.title}
                    </div>
                ))}
            </div>
        );
    }
}
