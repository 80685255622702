 // Initialize Firebase
 import firebase from 'firebase'
 require('firebase/firestore')
 
 var config = {
  apiKey: "AIzaSyCPsZQDa17mGcXzk4qmwFwHurKcmaidxPs",
  authDomain: "greatbeta-cfa05.firebaseapp.com",
  databaseURL: "https://greatbeta-cfa05.firebaseio.com",
  projectId: "greatbeta-cfa05",
  storageBucket: "greatbeta-cfa05.appspot.com",
  messagingSenderId: "118093317223",
  appId: "1:118093317223:web:86c951853a2789be9a00c2",
  measurementId: "G-HL1KDSWS7K"
};
  var firebase_init=firebase.initializeApp(config);
  export const Authprovider = new firebase.auth.GoogleAuthProvider();
  export const auth = firebase.auth();
  export const firestore=firebase.firestore();
  export const database=firebase.database();
  export const storageRef = firebase.storage().ref();
  export default firebase_init;