import React, { Component } from 'react';
import './MainPage.css';
import {
    Avatar,
    Typography,
    Input,
    Button,
    Tree,
    Modal,
    Select,
    Upload,
    message,
    List,
    Spin,
    Card,
    Popconfirm,
    notification,
    Drawer,
} from 'antd';
import {
    SettingFilled,
    ArrowLeftOutlined,
    ShareAltOutlined,
    UploadOutlined,
    DeleteOutlined,
    MoreOutlined,
    EditOutlined,
} from '@ant-design/icons';
import {
    getFolderList,
    setFolderList,
    uploadVideo,
    getVideoList,
    getFileUrl,
    uploadImage,
    getImageList,
    uploadPostToFirebase,
    getPostList,
    editPostToFirebase,
    deletePost,
    getVideoData,
    formatVideoName,
    saveVideoData,
    getVideoCover,
    deleteVideoData,
    saveToRedBar,
    deleteFile,
    checkPayRequestValid,
    updatePaymentIDList,
    updateUserBalance,
    getUserBalance,
    getUserStorageInfo,
    updateUserStorageInfo,
} from '../Service/Redux/service';
import { v4 as uuidv4 } from 'uuid';
import DirectoryTree from 'antd/lib/tree/DirectoryTree';
import CustomRichTextEditor from '../RichTextEditor';
import RichTextEditor from 'react-rte';
import TranscriptEditor from '@bbc/react-transcript-editor';
// import { Value } from "slate";
import ExampleTrans from './ExTranscript.json';
import VideoContainer from '../VideoView/videoViewContainer';
import VideoThumbnail from 'react-video-thumbnail';
import VideoPlayer from 'simple-react-video-thumbnail';
import ReactPlayer from 'react-player';
import AccPane from '../AccPane/AccPane';
import BreadCumb from '../BreadCumb/BreadCumb';
import { Loading } from '../loading';
import RedBar from '../RedBar/redbar';
import { Album } from '../Album/album';
import DepositComp from '../DepositComp/DepositComp';
var converter = require('byte-converter').converterBase10;
const { TreeNode } = Tree;
// const treeData = [
//   {
//     title: "parent 1",
//     key: "0-0",
//   },
// ];

const treeData = [
    {
        children: [
            { key: '0-0-0', level: 2, title: 'Tây Du Kí' },
            { key: '0-0-1', level: 2, title: 'Mỹ Hầu Vương 1986' },
        ],
        key: '0-0',
        level: 1,
        title: 'Thư mục tổng',
    },
];
const { Option } = Select;

// const initialEditorValue = Value.fromJSON({
//   document: {
//     nodes: [
//       {
//         object: "block",
//         type: "paragraph",
//         nodes: [
//           {
//             object: "text",
//             leaves: [
//               {
//                 text: "Nội dung bài viết"
//               }
//             ]
//           }
//         ]
//       }
//     ]
//   }
// });

const initialValue = RichTextEditor.createEmptyValue();

export default class MainPage extends Component {
    constructor(props) {
        super(props);

        let username = localStorage.getItem('username');
        if (!username) {
            this.props.history.push('/login');
        }

        this.state = {
            currentSelectTree: treeData,
            isFolderCreateModalVisible: false,
            isViewFileModalVisible: false,
            isUploadPostModalVisible: false,
            isViewPostVisible: false,
            createFolderName: '',
            treeData: treeData,
            currentPage: 'video',
            currentFileUrl: '',
            postTitle: '',
            postDes: '',
            postLang: 'vn',
            postViewLang: 'vn',
            videoList: [],
            imageList: [],
            postList: [],
            isLoading: false,
            postContent: initialValue,
            searchKey: '',
            transcriptData: ExampleTrans,
            mediaUrl: 'https://download.ted.com/talks/KateDarling_2018S-950k.mp4',
            isTextEditable: true,
            spellCheck: false,
            sttType: 'draftjs',
            analyticsEvents: [],
            title: '',
            fileName: '',
            autoSaveData: {},
            autoSaveContentType: 'draftjs',
            autoSaveExtension: 'json',
            currentFileName: '',
            videoData: [],
            currentEditVideoIndex: -1,
            currentEditVideoName: '',
            currentEditVideoDesc: '',
            videoThumbnailList: [],
            username,
            isChangeFolderNameMode: false,
            isLoadingApp: false,
            selectedAlbumKey: null,
            selectedRedBar: null,
            drawerVisible: false,
            userBalanceAmount: 0,
        };
        // getPostList((postList) => { this.setState({ postList }) })

        this.transcriptEditorRef = React.createRef();
    }

    toggleDrawer = (open) => {
        this.setState({ drawerVisible: open });
    };

    setLoadingApp = (isLoadingApp) => {
        this.setState({ isLoadingApp });
    };

    onDetailChange = ({ value }) => {
        this.setState({ postContent: value });
    };

    setVideoUrl = (name, url) => {
        let list = [...this.state.videoList];
        list = list.map((item) => {
            if (item.name === name) {
                item.url = url;
            }
            return item;
        });
        this.setState({ videoList: list });
    };

    setVideoData = (videoName, data) => {
        const videoData = [...this.state.videoData];
        const index = videoData.findIndex((v) => v.videoName === videoName);
        if (index >= 0) {
            videoData[index].data = data;
        } else {
            videoData.push({ videoName, data });
        }
        this.setState({ videoData });
    };

    addToVideoThumnailList = (list) => {
        console.log('Got thumbnail list', list);
        this.setState({ videoThumbnailList: list });
        list.map((img, index) => {
            console.log('Get hthumbanail url', img);
            getFileUrl(img.location, (url) => {
                let newList = [...this.state.videoThumbnailList];
                console.log('getVIdeo  thumblist', img);
                if (newList && newList[index]) {
                    newList[index].url = url;
                    this.setState({ videoThumbnailList: newList });
                }
            });
        });
    };

    setVideoList = (list) => {
        this.setState({ videoList: list });
        list.map((item) => {
            getVideoData(formatVideoName(item.name), (data) => this.setVideoData(item.name, data));
            getFileUrl(item.location, (url) => this.setVideoUrl(item.name, url));
        });
        getImageList('video-thumbnail', this.addToVideoThumnailList);
    };

    setImageList = (list) => {
        this.setState({ imageList: list });
        list.map((img, index) => {
            getFileUrl(img.location, (url) => {
                let newList = [...this.state.imageList];
                if (newList && newList[index]) {
                    newList[index].url = url;
                    this.setState({ imageList: newList });
                }
            });
        });
    };

    setTreeData = (data) => {
        if (!data) {
            setFolderList(treeData);
            data = treeData;
        }
        const ref = data[0].key;
        this.setState({
            currentSelectTree: data,
            treeData: data,
            selectedAlbumKey: ref,
        });
        getVideoList(ref, this.setVideoList);
        getImageList(ref, this.setImageList);
        getPostList(ref, (postList) => {
            this.setState({ postList });
        });
    };

    componentDidMount() {
        getFolderList(this.setTreeData.bind(this));
        console.log(JSON.stringify(treeData));

        this.handleUrlParams();
        getUserBalance((amount) => {
            this.setState({ userBalanceAmount: amount });
        });

        getUserStorageInfo((info) => this.setState({ storageInfo: info }));
    }

    handleUrlParams() {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const errorCode = urlParams.get('errorCode');
        const requestId = urlParams.get('requestId');
        const amount = urlParams.get('amount');
        console.log(errorCode, requestId, amount);
        if (!requestId || !amount) {
            return;
        }

        if (errorCode !== '0') {
            return;
        }

        checkPayRequestValid(requestId, (requestIds, isValid) => this.handleValidPayRequest(requestIds, isValid));
    }

    handleValidPayRequest(requestIds, isValid) {
        if (!isValid) {
            return;
        }
        updatePaymentIDList(requestIds);

        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const amount = urlParams.get('amount');

        updateUserBalance(amount);
    }

    onSelect = (selectedKeys, info) => {
        const tree = info.selectedNodes;
        console.log('selected', tree);
        this.setState({ currentSelectTree: tree, selectedRedBar: null });

        const ref = tree[0].key;
        this.getContentOfFolder(ref);
    };

    getContentOfFolder = (ref) => {
        this.setState({
            selectedAlbumKey: ref,
        });
        getVideoList(ref, this.setVideoList);
        getImageList(ref, this.setImageList);
        getPostList(ref, (postList) => {
            this.setState({ postList });
        });
    };

    onCheck(checkedKeys, info) {
        console.log('onCheck', checkedKeys, info);
    }

    onGetFolderList(data) {
        console.log('got data', data);
    }

    closeFolderCreateModal = () => {
        this.setState({
            isFolderCreateModalVisible: false,
            isChangeFolderNameMode: false,
        });
    };

    addFolderToTree = (tree, parentKey, childTitle, childKey) => {
        console.log('Trace to', parentKey, tree[0]);

        if (tree[0].key === parentKey) {
            if (tree[0].children) {
                tree[0].children.push({
                    title: childTitle,
                    key: childKey,
                    level: this.state.currentSelectTree[0].level + 1,
                });
            } else {
                tree[0].children = [];
                tree[0].children.push({
                    title: childTitle,
                    key: childKey,
                    level: this.state.currentSelectTree[0].level + 1,
                });
            }
            return tree[0];
        }

        if (tree[0].children) {
            tree[0].children = tree[0].children.map((branch) => {
                return this.addFolderToTree([branch], parentKey, childTitle, childKey);
            });
        }
        console.log('Trace', tree);
        return tree[0];
    };

    applyChangedFolderToTree = (tree, parentKey) => {
        if (tree[0].key === parentKey) {
            if (tree[0]) {
                tree[0].title = this.state.createFolderName;
            }
            return tree[0];
        }

        if (tree[0].children) {
            tree[0].children = tree[0].children.map((branch) => {
                return this.applyChangedFolderToTree([branch], parentKey);
            });
        }
        return tree[0];
    };

    changeFolderName() {
        const obString = JSON.stringify(this.state.treeData);
        const newObject = JSON.parse(obString);
        console.log('add child to', this.state.currentSelectTree);

        const currentTree = { ...this.state.currentSelectTree };
        currentTree[0].title = this.state.createFolderName;

        const newTree = this.applyChangedFolderToTree(newObject, currentTree[0].key);

        this.setState({ treeData: [newTree], currentSelectTree: currentTree });
        this.closeFolderCreateModal();
        setFolderList([newTree]);
    }

    createFolder = () => {
        if (this.state.isChangeFolderNameMode) {
            this.changeFolderName();
            return;
        }
        const key = uuidv4();
        const title = this.state.createFolderName;

        const obString = JSON.stringify(this.state.treeData);
        const newObject = JSON.parse(obString);
        console.log('add child to', this.state.currentSelectTree);
        const newTree = this.addFolderToTree(newObject, this.state.currentSelectTree[0].key, title, key);

        console.log('New currentTReeCHilde', newTree);
        // const newCurrentTree =
        //   [{
        //     ...this.state.currentSelectTree[0], children: newCurrentTreeChild
        //   }]

        // console.log('NEw current', newCurrentTree)
        this.setState({ treeData: [newTree] });
        this.closeFolderCreateModal();
        setFolderList([newTree]);
    };

    backToPreviosFolder = () => {
        const currentTree = this.state.treeStack[this.state.treeStack.length - 1];
        const treeStack = this.state.treeStack.filter((item) => item !== currentTree);
        this.setState({ currentSelectTree: currentTree, treeStack });
    };

    onUploadVideoFinish = () => {
        this.setState({ isLoading: false });
        const videoRef = this.state.selectedAlbumKey;
        getVideoList(videoRef, this.setVideoList);
    };

    onUploadImageFinish = () => {
        this.setState({ isLoading: false });
        const imageRef = this.state.selectedAlbumKey || this.state.selectedRedBar;
        getImageList(imageRef, this.setImageList);
    };

    beforeUploadVideo = (file) => {
        if (!this.checkFileSizeBeforeUpload(file)) {
            return;
        }
        this.setState({ isLoading: true });
        const ref = this.state.selectedAlbumKey + '/' + file.name;
        // this.getVideoThumb(file);
        uploadVideo(file, ref, () => this.onUploadVideoFinish());
    };

    async getVideoThumb(file) {
        try {
            // get the frame at 1.5 seconds of the video file
            const cover = await getVideoCover(file, 1.5);
            // print out the result image blob
            console.log('COver', cover);
            uploadImage(cover, 'video-thumbnail/' + formatVideoName(file.name));
        } catch (ex) {
            console.log('ERROR: ', ex);
        }
    }

    beforeUploadImage = (file) => {
        if (!this.checkFileSizeBeforeUpload(file)) {
            return;
        }
        this.setState({ isLoading: true });
        let ref = this.state.selectedAlbumKey || this.state.selectedRedBar;
        ref = ref + '/' + file.name;
        uploadImage(file, ref, () => this.onUploadImageFinish());
    };

    checkFileSizeBeforeUpload = (file) => {
        if (!file) {
            return false;
        }
        const size = converter(file.size, 'B', 'MB');
        if (!this.state.storageInfo) {
            return;
        }
        if (this.state.storageInfo.remain < size) {
            message.info('Không đủ dung lượng còn lại');
        }

        const newInfo = {
            ...this.state.storageInfo,
            remain: Number(this.state.storageInfo.remain) - size,
        };
        updateUserStorageInfo(newInfo);
        return true;
    };

    onUpload = (info) => {
        if (info.file.status === 'done') {
            message.success(`${info.file.name} file uploaded successfully`);
        } else if (info.file.status === 'error') {
            message.error(`${info.file.name} file upload failed.`);
        }
    };

    renderFolderTree = () => {
        return (
            <div>
                <div className="w-full border border-gray-400 text-xl">
                    <Button type="link" onClick={() => this.setState({ isFolderCreateModalVisible: true })}>
                        Sanh cấp {this.state.currentSelectTree[0].level}+
                    </Button>
                </div>
                <div className="p-3 border-x border-gray-400">
                    <DirectoryTree onSelect={this.onSelect} onCheck={this.onCheck} treeData={this.state.treeData} />
                </div>
                <div className="w-full flex gap-5 border border-gray-400 text-xl">
                    <Button type="link" onClick={() => this.saveDataToRedBar()}>
                        Hiển thị thanh
                    </Button>
                    <Select defaultValue="video" onSelect={(ev) => this.setState({ currentPage: ev })}>
                        <Option value="post">Bài đăng</Option>
                        <Option value="video">Video</Option>
                        <Option value="image">Hình ảnh</Option>
                    </Select>
                    <Button
                        type="link"
                        onClick={() =>
                            this.setState({
                                isFolderCreateModalVisible: true,
                                isChangeFolderNameMode: true,
                            })
                        }
                    >
                        Đổi tên cấp
                    </Button>
                </div>
            </div>
        );
    };

    showFileView = (url) => {
        this.setState({
            currentFileUrl: url,
            isViewFileModalVisible: true,
            isLoading: false,
        });
    };

    openFileView = (location, name?) => {
        this.setState({ isLoading: true, currentFileName: name });
        getFileUrl(location, this.showFileView);
    };

    onVideoDescEdit = (isName, ev) => {
        if (isName) {
            this.setState({ currentEditVideoName: ev.target.value });
            return;
        }
        this.setState({ currentEditVideoDesc: ev.target.value });
    };

    showVideoEdit = (index) => {
        if (this.state.currentEditVideoIndex !== index) {
            this.setState({
                currentEditVideoName: '',
                currentEditVideoDesc: '',
                currentEditVideoIndex: index,
            });
        } else {
            this.setState({
                currentEditVideoName: '',
                currentEditVideoDesc: '',
                currentEditVideoIndex: -1,
            });
        }
    };

    saveVideoDesc = (item) => {
        let data = this.state.videoData.filter((v) => v.videoName === item.name)[0];
        let videoName = this.state.currentEditVideoName;
        let videoDesc = this.state.currentEditVideoDesc;
        if (data) {
            videoName = videoName || data.data.videoName;
            videoDesc = videoDesc || data.data.videoDesc;
        }

        saveVideoData(formatVideoName(item.name), {
            videoName,
            videoDesc,
        });
        this.setVideoData(item.name, {
            videoName,
            videoDesc,
        });
        this.setState({
            currentEditVideoName: '',
            currentEditVideoDesc: '',
            currentEditVideoIndex: -1,
        });
    };

    deleteVideo = (item) => {
        let data = this.state.videoData.filter((v) => v.videoName === item.name)[0];
        console.log('Delete file', data);
        const ref = this.state.selectedAlbumKey + '/' + data.videoName;

        deleteVideoData(formatVideoName(data.videoName), ref);

        const videoData = [...this.state.videoData];
        const videoList = [...this.state.videoList];
        const index = videoData.findIndex((v) => v.videoName === data.videoName);
        if (index >= 0) {
            videoData.splice(index, 1);
            videoList.splice(index, 1);
        }
        console.log('Video data', videoData);
        this.setState({ videoData, videoList });
    };

    onSelectAlbum = (albumKey) => {
        if (albumKey !== this.state.selectedAlbumKey) {
            this.setState({ selectedAlbumKey: albumKey });
            getVideoList(albumKey, this.setVideoList);
            getImageList(albumKey, this.setImageList);
        }
    };

    renderVideoUpload = () => {
        const data = this.state.videoList;
        if (this.state.isViewFileModalVisible) {
            return;
        }
        let folderRef = this.state.currentSelectTree[0].key;
        if (this.state.selectedRedBar) {
            folderRef = this.state.selectedRedBar;
        }
        return (
            <div className="video-upload-section">
                <Upload beforeUpload={this.beforeUploadVideo}>
                    <Button>
                        <UploadOutlined /> Tải file lên
                    </Button>
                </Upload>

                <div className="pt-5 video-list">
                    <List
                        grid={{
                            gutter: 24,
                            xs: 1,
                            sm: 2,
                            md: 2,
                            lg: 4,
                            xl: 3,
                            xxl: 3,
                        }}
                        dataSource={data}
                        renderItem={(item, index) => {
                            const data = this.state.videoData.filter((v) => v.videoName === item.name)[0];

                            let videoName = item.name;
                            let videoDesc = 'Mô Tả';
                            if (data && data.data) {
                                videoName = data.data.videoName || videoName;
                                videoDesc = data.data.videoDesc || videoDesc;
                            }

                            const thumbnailData = this.state.videoThumbnailList.filter((v) => {
                                console.log(v.name);
                                return v.name === formatVideoName(item.name);
                            })[0];

                            let thumbUrl = null;
                            if (thumbnailData) {
                                console.log('Has thumb', thumbnailData);
                                thumbUrl = thumbnailData.url;
                            }

                            return (
                                <List.Item>
                                    {thumbUrl ? (
                                        <div
                                            className="video-item-list"
                                            style={{
                                                backgroundImage: `url(${thumbUrl})`,
                                                backgroundSize: 'cover',
                                            }}
                                            onClick={() => this.openFileView(item.location, item.name)}
                                        />
                                    ) : (
                                        <div
                                            className="video-item-list"
                                            onClick={() => this.openFileView(item.location, item.name)}
                                        >
                                            {item.name}
                                        </div>
                                    )}

                                    <div className="video-item-list-desc">
                                        <div>
                                            <strong>{videoName}</strong>
                                            <Button
                                                shape="circle"
                                                icon={<EditOutlined />}
                                                style={{ marginLeft: '10px' }}
                                                onClick={() => this.showVideoEdit(index)}
                                            />
                                        </div>
                                        <span>{videoDesc}</span>
                                    </div>
                                    {this.state.currentEditVideoIndex === index && (
                                        <div className="video-item-list-desc-editor">
                                            <Input
                                                placeholder={videoName}
                                                value={this.state.currentEditVideoName}
                                                onChange={(ev) => this.onVideoDescEdit(true, ev)}
                                            />
                                            <Input
                                                placeholder={videoDesc}
                                                value={this.state.currentEditVideoDesc}
                                                onChange={(ev) => this.onVideoDescEdit(false, ev)}
                                            />
                                            <Button onClick={() => this.saveVideoDesc(item)}>Lưu</Button>
                                            <Button onClick={() => this.deleteVideo(item)}>Xóa</Button>
                                        </div>
                                    )}
                                    {/* } */}
                                </List.Item>
                            );
                        }}
                    />
                    ,
                </div>
                <Album folderRef={folderRef} onSelectAlbum={this.onSelectAlbum} selectedAlbumKey={this.state.selectedAlbumKey} />
            </div>
        );
    };

    onPostContentChange = (value) => {
        this.setState({ postContent: value });
        // const content = JSON.stringify(value.toJSON())
    };

    uploadPost() {
        const ref = this.state.currentSelectTree[0].key;
        uploadPostToFirebase(
            ref,
            this.state.postLang,
            this.state.postTitle,
            this.state.postDes,
            this.state.postContent.toString('html')
        );
        this.setState({ isUploadPostModalVisible: false });
    }

    renderUploadPostModal() {
        if (!this.state.isUploadPostModalVisible) {
            return;
        }
        return (
            // <Modal
            //   title="Tạo thư mục"
            //   visible={this.state.isUploadPostModalVisible}
            //   onOk={() => this.uploadPost()}
            //   onCancel={() => {
            //     this.setState({ isUploadPostModalVisible: false });
            //   }}
            // >
            <div>
                <Select defaultValue="vn" onSelect={(ev) => this.setState({ postLang: ev })}>
                    <Option value="vn">Tiếng Việt</Option>
                    <Option value="en">English</Option>
                    <Option value="cn">Chinese</Option>
                </Select>
                <Input
                    placeholder="Tiêu đề"
                    onChange={(ev) => {
                        this.setState({ postTitle: ev.target.value });
                    }}
                />
                <Input
                    placeholder="Mô tả"
                    onChange={(ev) => {
                        this.setState({ postDes: ev.target.value });
                    }}
                />
                <CustomRichTextEditor value={this.state.postContent} onChange={this.onPostContentChange} />
                <Button onClick={() => this.uploadPost()}>Lưu bài đăng</Button>
                <Button onClick={() => this.setState({ isUploadPostModalVisible: false })}>Quay lại</Button>
            </div>
        );
    }

    setCurrentViewPostContent = (lang) => {
        let post = this.state.currentViewPostData.list.find((p) => p.lang == lang);
        if (!post) {
            post = {
                title: this.state.currentViewPostData.title,
                des: this.state.currentViewPostData.des,
                lang,
                content: initialValue.toString('html'),
            };
        }
        this.setState({
            // currentViewPost: post,
            currentViewPostLang: lang,
            currentViewPostContent: RichTextEditor.createValueFromString(post.content, 'html'),
        });
    };

    onPostViewContentChange = (value) => {
        this.setState({
            currentViewPostContent: value,
        });
    };

    editPost = () => {
        const ref = this.state.currentSelectTree[0].key;
        this.setState({ isViewPostVisible: false });
        editPostToFirebase(
            ref,
            this.state.currentViewPostData.key,
            this.state.currentViewPostLang,
            this.state.currentViewPostData.title,
            this.state.currentViewPostData.des,
            this.state.currentViewPostContent.toString('html')
        );
    };

    renderViewPostModal() {
        if (!this.state.isViewPostVisible) {
            return;
        }
        const originalContent = this.state.currentViewPost
            ? RichTextEditor.createValueFromString(this.state.currentViewPost.content, 'html')
            : null;
        const content = this.state.currentViewPostContent ? this.state.currentViewPostContent : initialValue;
        const title = this.state.currentViewPost ? this.state.currentViewPost.title : '';
        const des = this.state.currentViewPost ? this.state.currentViewPost.des : '';
        console.log(content, this.state.currentViewPostLang);
        return (
            // <Modal
            //   title="Tạo thư mục"
            //   visible={this.state.isViewPostVisible}
            //   onOk={() => this.editPost()}
            //   onCancel={() => {
            //     this.setState({ isViewPostVisible: false });
            //   }}
            //   width={"90vw"}
            // >
            <div>
                <Select
                    value={this.state.currentViewPostLang}
                    onSelect={(ev) => this.setCurrentViewPostContent(ev)}
                    style={{ width: '50%' }}
                >
                    <Option value="vn">Tiếng Việt</Option>
                    <Option value="en">English</Option>
                    <Option value="cn">Chinese</Option>
                </Select>
                <Input value={title} />
                <Input value={des} />
                <div className="post-content-edit">
                    {originalContent && <CustomRichTextEditor value={originalContent} />}
                    {/* <CustomRichTextEditor
            value={content}
            subClass="post-translate-editor"
            onChange={this.onPostViewContentChange}
          /> */}
                </div>
                <Button onClick={() => this.editPost()}>Lưu bài đăng</Button>
                <Button onClick={() => this.setState({ isViewPostVisible: false })}>Quay lại</Button>
            </div>
            // </Modal>
        );
    }

    openViewPostModal = (post) => {
        this.setState({
            currentViewPostData: post,
            currentViewPost: post.list[0],
            currentViewPostLang: post.list[0].lang,
            currentViewPostContent: RichTextEditor.createValueFromString(post.list[0].content, 'html'),
            isViewPostVisible: true,
        });
    };

    confirmDeletePost(key) {
        const ref = this.state.currentSelectTree[0].key;
        deletePost(ref, key);
    }

    renderPostList() {
        if (this.state.isUploadPostModalVisible || this.state.isViewPostVisible) {
            return;
        }
        const data = this.state.postList.filter((post) => {
            let isPostContainKey = false;
            console.log(post.list);
            isPostContainKey = post.list.find(
                (p) =>
                    p.title.includes(this.state.searchKey) ||
                    p.content.includes(this.state.searchKey) ||
                    p.des.includes(this.state.searchKey)
            )
                ? true
                : false;
            return isPostContainKey;
        });
        console.log(data);
        return (
            <div>
                <Button
                    onClick={() => {
                        this.setState({ isUploadPostModalVisible: true });
                    }}
                >
                    Tạo bài đăng
                </Button>
                <List
                    grid={{
                        gutter: 16,
                        xs: 1,
                        sm: 2,
                        md: 1,
                        lg: 1,
                        xl: 1,
                        xxl: 1,
                    }}
                    dataSource={data}
                    renderItem={(item) => (
                        <List.Item key={item.key} style={{ marginBottom: '20px' }}>
                            <div className="post-item-container">
                                <Card onClick={() => this.openViewPostModal(item)} className="post-item" bordered={false}>
                                    {/* <RichTextEditor readOnly={true} value={RichTextEditor.createValueFromString(item.content, 'html')}>
              </RichTextEditor> */}
                                    <img className="post-img" />
                                    <div className="post-review">
                                        <h3 className="post-content-review">{item.title}</h3>
                                        <div className="post-content-review">{item.des}</div>
                                    </div>
                                </Card>
                                <div className="post-item-action">
                                    <Popconfirm
                                        placement="topLeft"
                                        title={'Xóa bài đăng'}
                                        onConfirm={() => this.confirmDeletePost(item.key)}
                                        okText="Xóa"
                                        cancelText="Hủy"
                                    >
                                        <DeleteOutlined />
                                    </Popconfirm>
                                </div>
                            </div>
                        </List.Item>
                    )}
                />
            </div>
        );
    }

    renderPostUpload() {
        return (
            <div>
                {this.renderUploadPostModal()}
                {this.renderViewPostModal()}
                {this.renderPostList()}
            </div>
        );
    }

    deleteImage = () => {
        const ref = this.state.currentSelectTree[0].key + '/' + this.state.currentFileName;
        deleteFile('images', ref);
        const imageList = [...this.state.imageList];
        const newList = imageList.filter((img) => img.name !== this.state.currentFileName);
        this.setState({ isViewFileModalVisible: false, imageList: newList });
    };

    renderImageUpload() {
        if (this.state.isViewFileModalVisible) {
            return;
        }
        const data = this.state.imageList;
        console.log('Image list', data);
        let folderRef = this.state.currentSelectTree[0].key;
        if (this.state.selectedRedBar) {
            folderRef = this.state.selectedRedBar;
        }
        return (
            <div className="video-upload-section">
                <Upload beforeUpload={this.beforeUploadImage}>
                    <Button>
                        <UploadOutlined />Tải file lên
                    </Button>
                </Upload>
                <div className="pt-5 video-list">
                    <List
                        grid={{ gutter: 24, xs: 1, sm: 2, md: 2, lg: 4, xl: 3, xxl: 3 }}
                        dataSource={data}
                        renderItem={(item) => (
                            <List.Item onClick={() => this.openFileView(item.location, item.name)}>
                                <div className="video-item-list">
                                    <img
                                        src={item.url ? item.url : ''}
                                        style={{
                                            objectFit: 'cover',
                                            width: '100%',
                                            height: '100%',
                                        }}
                                    />
                                </div>
                            </List.Item>
                        )}
                    />
                </div>
                <Album
                    folderRef={folderRef}
                    onSelectAlbum={this.onSelectAlbum}
                    selectedAlbumKey={this.state.selectedAlbumKey}
                    type="image"
                />
            </div>
        );
    }

    changeCurrentPage = (page) => {
        this.setState({ currentPage: page });
    };

    renderFolderSection = () => {
        return (
            <div className="upload-section-folder">
                {/* <div style={{ width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}> */}
                <div>
                    {/* {
            this.state.treeStack.length > 1 &&
            <ArrowLeftOutlined onClick={this.backToPreviosFolder} />
          } */}
                    {/* <Button style={{ width: "50%", marginLeft: '20px' }} block onClick={() => { this.setState({ isFolderCreateModalVisible: true }) }}>
            Tạo thư mục mới
        </Button> */}
                    {/* <Select
            defaultValue="folder"
            onSelect={(ev) => this.setState({ currentPage: ev })}
          >
            <Option value="folder">Thư mục</Option>
            <Option value="image">Hình ảnh</Option>
            <Option value="video">Video</Option>
            <Option value="post">Đăng bài</Option>
          </Select> */}
                </div>

                <div style={{ width: '100%' }}>
                    {this.renderFolderTree()}
                    {this.state.currentPage == 'video' && this.renderVideoUpload()}
                    {this.state.currentPage == 'image' && this.renderImageUpload()}
                    {this.state.currentPage == 'post' && this.renderPostUpload()}
                    {this.renderViewFileModal()}
                </div>
            </div>
        );
    };

    renderCreateModal = () => {
        return (
            <Modal
                title="Tạo thư mục"
                visible={this.state.isFolderCreateModalVisible}
                onOk={this.createFolder}
                onCancel={this.closeFolderCreateModal}
            >
                <Input
                    value={this.state.createFolderName}
                    onChange={(ev) => this.setState({ createFolderName: ev.target.value })}
                />
            </Modal>
        );
    };

    handleAutoSaveChanges = (newAutoSaveData) => {
        console.log('handleAutoSaveChanges', newAutoSaveData);
        const { data, ext } = newAutoSaveData;
        this.setState({ autoSaveData: data, autoSaveExtension: ext });
        // Saving to local storage
    };

    deleteFile = () => {
        this.deleteImage();
    };

    renderViewFileModal = () => {
        if (this.state.isViewFileModalVisible) {
            if (this.state.currentPage == 'video') {
                return (
                    <div>
                        <Button onClick={() => this.setState({ isViewFileModalVisible: false })}>Quay về</Button>
                        <VideoContainer videoUrl={this.state.currentFileUrl} videoName={this.state.currentFileName} />
                    </div>
                );
            } else {
                return (
                    <div className="view-file-modal">
                        <div className="view-file-action">
                            <Button onClick={() => this.setState({ isViewFileModalVisible: false })}>Quay về</Button>
                            <Button onClick={this.deleteFile}>Xóa</Button>
                        </div>

                        <img src={this.state.currentFileUrl} className="file-reviewer" />
                    </div>
                );
            }
        }
    };

    searchForPost = (value) => {
        this.setState({ searchKey: value });
    };

    saveDataToRedBar() {
        if (this.state.currentPage == 'folder') {
            return;
        }
        saveToRedBar(this.state.currentSelectTree[0].key, this.state.currentSelectTree[0].title, this.state.currentPage);
        notification.success('Đã lưu vào thanh đỏ');
    }

    onSelectRedBar = (item) => {
        console.log('Redbar select', item, item.type);
        this.setState({ selectedRedBar: item.key });
        this.getContentOfFolder(item.key);
        this.changeCurrentPage(item.type);
    };

    renderDrawer() {
        return (
            <Drawer
                placement="right"
                closable={false}
                onClose={() => this.toggleDrawer(false)}
                visible={this.state.drawerVisible}
            >
                <div className="upload-section-acc-pane">
                    <AccPane changePage={this.changeCurrentPage} currentPage={this.state.currentPage} />
                </div>
            </Drawer>
        );
    }

    render() {
        return (
            <div>
                <Loading isLoading={this.state.isLoadingApp} />
                {this.renderCreateModal()}
                {this.renderDrawer()}
                <div className="mainpage-header">
                    <div className="upload-section" style={{ width: '100%' }}>
                        <div style={{ width: '65%' }}>
                            <BreadCumb
                                changePage={this.changeCurrentPage}
                                currentPage={this.state.currentPage}
                                openDrawer={() => this.toggleDrawer(true)}
                            />
                            <Input.Search
                                placeholder="Tìm kiếm"
                                onSearch={(value) => this.searchForPost(value)}
                                onChange={(e) => this.searchForPost(e.target.value)}
                                style={{ width: 350 }}
                            />
                        </div>
                        {/* <DepositComp amount={this.state.userBalanceAmount} /> */}
                    </div>
                </div>
                <RedBar onSelect={(item) => this.onSelectRedBar(item)} />
                {/* <div className="upload-section">
                    <Input
                        style={{ width: '75%' }}
                        addonAfter="0kb"
                        value={this.state.currentSelectTree[0].title}
                        disabled={true}
                    />
                </div> */}

                <div className="upload-section">
                    {/* <div className="upload-section-feature">
            <Button type="link">Tạo mới</Button>
            <Button type="link">Hình ảnh</Button>
            <Button type="link">Video</Button>
            <Button type="link">Mp3</Button>
            <Button type="link">Đăng bài</Button>
            <Button type="link">Bình luận</Button>
          </div> */}
                    {this.renderFolderSection()}
                    {/* {!this.state.isViewFileModalVisible && !this.state.isUploadPostModalVisible && !this.state.isViewPostVisible && (
                        <div className="upload-section-acc-pane">
                            <AccPane changePage={this.changeCurrentPage} currentPage={this.state.currentPage} />
                        </div>
                    )} */}
                </div>

                {this.state.isLoading && (
                    <div className="loading">
                        <Spin />
                    </div>
                )}
            </div>
        );
    }
}
