import React, { Component } from "react";
import { Input, Button } from "antd";
// import { FirebaseClientService } from '@canner/image-service-config';
import firebase_init from "./Service/firebase";
// import CannerEditor from 'canner-slate-editor'
// import { Value } from 'slate'
// import './RichTextEditor.css'
import RichTextEditor from "react-rte";

const imageService = {
  firebase: firebase_init,
  dir: "/doc_image", // specify the path you want upload to
  filename: "filename", // rename file without extension
  hash: false, // if true, the filename will add a hash string, e.g.: `filename-${hash}.jpg`
};

let CustomRequestArgs = {
  onProgress: (percent) => console.log("progress"),
  onError: (event, body) => console.log("error"),
  onSuccess: (body) => console.log("success"),
  data: Object,
  filename: String,
  file: File,
  withCredentials: Boolean,
  action: String,
  headers: Object,
};

function getServiceConfig() {
  return {
    customRequest: function(CustomRequestArgs) {
      const { file, onProgress, onSuccess, onError } = CustomRequestArgs;
      console.log("upload");
      const images = imageService.firebase
        .storage()
        .ref(imageService.dir || "")
        .child(imageService.filename);
      const uploadTask = images.put(file);
      uploadTask.on(
        "state_changed",
        function(snapshot) {
          const percent =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          onProgress({ percent });
        },
        function(e) {
          onError(e);
        },
        function() {
          uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
            onSuccess({ data: { link: downloadURL } });
          });
        }
      );
    },
  };
}

const serviceConfig = getServiceConfig();

const initialValue = RichTextEditor.createEmptyValue();

export default class CustomRichTextEditor extends Component {
  constructor(props) {
    super(props);
    this.state = { value: initialValue };
  }

  onChange = (value) => {
    console.log(value);
    this.setState({ value });
    // const content = JSON.stringify(value.toJSON())
  };
  render() {
    return (
      <div className={`RichTextEditorContainer ${this.props.subClass || ""} `}>
        {/* <CannerEditor
          ref="editor"
          value={this.props.value}
          onChange={this.props.onDetailChange}
          serviceConfig={serviceConfig}

        /> */}
        <RichTextEditor
          value={this.props.value}
          onChange={this.props.onChange}
        />
      </div>
    );
  }
}
