import React, { Component } from "react";
import "./UploadPage.css";
import {
  Avatar,
  Typography,
  Input,
  Button,
  Tree,
  Modal,
  Select,
  Upload,
  message,
  List,
  Spin,
  Card,
  Popconfirm,
} from "antd";
import {
  SettingFilled,
  ArrowLeftOutlined,
  ShareAltOutlined,
  UploadOutlined,
  DeleteOutlined,
  MoreOutlined,
  EditOutlined,
} from "@ant-design/icons";
import {
  getFolderList,
  setFolderList,
  uploadVideo,
  getVideoList,
  getFileUrl,
  uploadImage,
  getImageList,
  uploadPostToFirebase,
  getPostList,
  editPostToFirebase,
  deletePost,
  getVideoData,
  formatVideoName,
  saveVideoData, getVideoCover
} from "../Service/Redux/service";
import { v4 as uuidv4 } from "uuid";
import DirectoryTree from "antd/lib/tree/DirectoryTree";
import CustomRichTextEditor from "../RichTextEditor";
import RichTextEditor from "react-rte";
import TranscriptEditor from "@bbc/react-transcript-editor";
// import { Value } from "slate";
import ExampleTrans from "./ExTranscript.json";
import VideoContainer from "../VideoView/videoViewContainer";
import VideoThumbnail from "react-video-thumbnail";
import VideoPlayer from "simple-react-video-thumbnail";
import ReactPlayer from "react-player";
const { TreeNode } = Tree;
// const treeData = [
//   {
//     title: "parent 1",
//     key: "0-0",
//   },
// ];

const treeData = [
  {
    children: [
      { key: "0-0-0", level: 2, title: "Tây Du Kí" },
      { key: "0-0-1", level: 2, title: "Mỹ Hầu Vương 1986" },
    ],
    key: "0-0",
    level: 1,
    title: "Thư mục tổng",
  },
];
const { Option } = Select;

// const initialEditorValue = Value.fromJSON({
//   document: {
//     nodes: [
//       {
//         object: "block",
//         type: "paragraph",
//         nodes: [
//           {
//             object: "text",
//             leaves: [
//               {
//                 text: "Nội dung bài viết"
//               }
//             ]
//           }
//         ]
//       }
//     ]
//   }
// });

const initialValue = RichTextEditor.createEmptyValue();

export default class UploadPage extends Component {
  constructor(props) {
    super(props);

    let username = localStorage.getItem("username");
    if (!username) {
      this.props.history.push("/login");
    }

    this.state = {
      currentSelectTree: treeData,
      isFolderCreateModalVisible: false,
      isViewFileModalVisible: false,
      isUploadPostModalVisible: false,
      isViewPostVisible: false,
      createFolderName: "",
      treeData: treeData,
      currentPage: "video",
      currentFileUrl: "",
      postTitle: "",
      postDes: "",
      postLang: "vn",
      postViewLang: "vn",
      videoList: [],
      imageList: [],
      postList: [],
      isLoading: false,
      postContent: initialValue,
      searchKey: "",
      transcriptData: ExampleTrans,
      mediaUrl: "https://download.ted.com/talks/KateDarling_2018S-950k.mp4",
      isTextEditable: true,
      spellCheck: false,
      sttType: "draftjs",
      analyticsEvents: [],
      title: "",
      fileName: "",
      autoSaveData: {},
      autoSaveContentType: "draftjs",
      autoSaveExtension: "json",
      currentFileName: "",
      videoData: [],
      currentEditVideoIndex: -1,
      currentEditVideoName: "",
      currentEditVideoDesc: "",
      videoThumbnailList: [],
      username,
    };
    // getPostList((postList) => { this.setState({ postList }) })

    this.transcriptEditorRef = React.createRef();
  }

  onDetailChange = ({ value }) => {
    this.setState({ postContent: value });
  };

  setVideoUrl = (name, url) => {
    let list = [...this.state.videoList];
    list = list.map((item) => {
      if (item.name === name) {
        item.url = url;
      }
      return item;
    });
    this.setState({ videoList: list });
  };

  setVideoData = (videoName, data) => {
    const videoData = [...this.state.videoData];
    const index = videoData.findIndex((v) => v.videoName === videoName);
    if (index >= 0) {
      videoData[index].data = data;
    } else {
      videoData.push({ videoName, data });
    }
    this.setState({ videoData });
  };

  addToVideoThumnailList = (list) => {
    console.log("Got thumbnail list", list);
    this.setState({ videoThumbnailList: list });
    list.map((img, index) => {
      console.log("Get hthumbanail url", img);
      getFileUrl(img.location, (url) => {
        let newList = [...this.state.videoThumbnailList];
        console.log("getVIdeo  thumblist", img);
        if (newList && newList[index]) {
          newList[index].url = url;
          this.setState({ videoThumbnailList: newList });
        }
      });
    });
  };

  setVideoList = (list) => {
    this.setState({ videoList: list });
    list.map((item) => {
      getVideoData(formatVideoName(item.name), (data) =>
        this.setVideoData(item.name, data)
      );
      getFileUrl(item.location, (url) => this.setVideoUrl(item.name, url));
    });
    getImageList("video-thumbnail", this.addToVideoThumnailList);
  };

  setImageList = (list) => {
    this.setState({ imageList: list });
    list.map((img, index) => {
      getFileUrl(img.location, (url) => {
        let newList = [...this.state.imageList];
        if (newList && newList[index]) {
          newList[index].url = url;
          this.setState({ imageList: newList });
        }
      });
    });
  };

  setTreeData = (data) => {
    if (!data) {
      setFolderList(treeData);
      data = treeData;
    }
    this.setState({ currentSelectTree: data, treeData: data });
    const ref = data[0].key;
    getVideoList(ref, this.setVideoList);
    getImageList(ref, this.setImageList);
    getPostList(ref, (postList) => {
      this.setState({ postList });
    });
  };

  componentDidMount() {
    getFolderList(this.setTreeData.bind(this));
    console.log(JSON.stringify(treeData));
  }

  onSelect = (selectedKeys, info) => {
    const tree = info.selectedNodes;
    console.log("selected", tree);
    this.setState({ currentSelectTree: tree });

    const ref = tree[0].key;
    getVideoList(ref, this.setVideoList);
    getImageList(ref, this.setImageList);
    getPostList(ref, (postList) => {
      this.setState({ postList });
    });
  };

  onCheck(checkedKeys, info) {
    console.log("onCheck", checkedKeys, info);
  }

  onGetFolderList(data) {
    console.log("got data", data);
  }

  closeFolderCreateModal = () => {
    this.setState({
      isFolderCreateModalVisible: false,
    });
  };

  addFolderToTree = (tree, parentKey, childTitle, childKey) => {
    console.log("Trace to", parentKey, tree[0]);

    if (tree[0].key === parentKey) {
      if (tree[0].children) {
        tree[0].children.push({
          title: childTitle,
          key: childKey,
          level: this.state.currentSelectTree[0].level + 1,
        });
      } else {
        tree[0].children = [];
        tree[0].children.push({
          title: childTitle,
          key: childKey,
          level: this.state.currentSelectTree[0].level + 1,
        });
      }
      return tree[0];
    }

    if (tree[0].children) {
      tree[0].children = tree[0].children.map((branch) => {
        // if (branch.key === parentKey) {
        //   if (branch.children) {
        //     branch.children.push({
        //       title: childTitle,
        //       key: childKey,
        //       level: this.state.currentSelectTree[0].level + 1
        //     })
        //   } else {
        //     branch.children = [];
        //     branch.children.push({
        //       title: childTitle,
        //       key: childKey,
        //       level: this.state.currentSelectTree[0].level + 1
        //     })
        //   }
        //   return branch;
        // }
        // if (branch.children) {
        //   return this.addFolderToTree([branch], parentKey, childTitle, childKey)
        // }
        return this.addFolderToTree([branch], parentKey, childTitle, childKey);
      });
    }
    console.log("Trace", tree);
    return tree[0];
  };

  createFolder = () => {
    const key = uuidv4();
    const title = this.state.createFolderName;

    const obString = JSON.stringify(this.state.treeData);
    const newObject = JSON.parse(obString);
    console.log("add child to", this.state.currentSelectTree);
    const newTree = this.addFolderToTree(
      newObject,
      this.state.currentSelectTree[0].key,
      title,
      key
    );

    console.log("New currentTReeCHilde", newTree);
    // const newCurrentTree =
    //   [{
    //     ...this.state.currentSelectTree[0], children: newCurrentTreeChild
    //   }]

    // console.log('NEw current', newCurrentTree)
    this.setState({ treeData: [newTree] });
    this.closeFolderCreateModal();
    setFolderList([newTree]);
  };

  backToPreviosFolder = () => {
    const currentTree = this.state.treeStack[this.state.treeStack.length - 1];
    const treeStack = this.state.treeStack.filter(
      (item) => item !== currentTree
    );
    this.setState({ currentSelectTree: currentTree, treeStack });
  };

  onUploadVideoFinish = () => {
    this.setState({ isLoading: false });
    const videoRef = this.state.currentSelectTree[0].key;
    getVideoList(videoRef, this.setVideoList);
  };

  onUploadImageFinish = () => {
    this.setState({ isLoading: false });
    const imageRef = this.state.currentSelectTree[0].key;
    getImageList(imageRef, this.setImageList);
  };

  beforeUploadVideo = (file) => {
    console.log("upload file", file.name);
    this.setState({ isLoading: true });
    const ref = this.state.currentSelectTree[0].key + "/" + file.name;
    // this.getVideoThumb(file);
    uploadVideo(file, ref, () => this.onUploadVideoFinish());
  };

  async getVideoThumb(file) {
    try {
      // get the frame at 1.5 seconds of the video file
      const cover = await getVideoCover(file, 1.5);
      // print out the result image blob
      console.log("COver", cover);
      uploadImage(cover, "video-thumbnail/" + formatVideoName(file.name));
    } catch (ex) {
      console.log("ERROR: ", ex);
    }
  }

  beforeUploadImage = (file) => {
    console.log("upload file", file.name);
    this.setState({ isLoading: true });
    const ref = this.state.currentSelectTree[0].key + "/" + file.name;
    uploadImage(file, ref, () => this.onUploadImageFinish());
  };

  onUpload = (info) => {
    if (info.file.status !== "uploading") {
      console.log(info.file, info.fileList);
    }
    if (info.file.status === "done") {
      message.success(`${info.file.name} file uploaded successfully`);
    } else if (info.file.status === "error") {
      message.error(`${info.file.name} file upload failed.`);
    }
  };

  renderFolderTree = () => {
    return (
      <DirectoryTree
        onSelect={this.onSelect}
        onCheck={this.onCheck}
        treeData={this.state.treeData}
      />
    );
  };

  showFileView = (url) => {
    this.setState({
      currentFileUrl: url,
      isViewFileModalVisible: true,
      isLoading: false,
    });
  };

  openFileView = (location, name?) => {
    this.setState({ isLoading: true, currentFileName: name });
    getFileUrl(location, this.showFileView);
  };

  onVideoDescEdit = (isName, ev) => {
    if (isName) {
      this.setState({ currentEditVideoName: ev.target.value });
      return;
    }
    this.setState({ currentEditVideoDesc: ev.target.value });
  };

  showVideoEdit = (index) => {
    if (this.state.currentEditVideoIndex !== index) {
      this.setState({
        currentEditVideoName: "",
        currentEditVideoDesc: "",
        currentEditVideoIndex: index,
      });
    } else {
      this.setState({
        currentEditVideoName: "",
        currentEditVideoDesc: "",
        currentEditVideoIndex: -1,
      });
    }
  };

  saveVideoDesc = (item) => {
    let data = this.state.videoData.filter((v) => v.videoName === item.name)[0];
    let videoName = this.state.currentEditVideoName;
    let videoDesc = this.state.currentEditVideoDesc;
    if (data) {
      videoName = videoName || data.data.videoName;
      videoDesc = videoDesc || data.data.videoDesc;
    }

    saveVideoData(formatVideoName(item.name), {
      videoName,
      videoDesc,
    });
    this.setVideoData(item.name, {
      videoName,
      videoDesc,
    });
    this.setState({
      currentEditVideoName: "",
      currentEditVideoDesc: "",
      currentEditVideoIndex: -1,
    });
  };

  renderVideoUpload = () => {
    const data = this.state.videoList;
    console.log(this.state.videoData);
    return (
      <div className="video-upload-section">
        <Upload beforeUpload={this.beforeUploadVideo}>
          <Button>
            <UploadOutlined /> Click to Upload
          </Button>
        </Upload>
        <div className="video-list">
          <List
            grid={{ gutter: 4, column: 3 }}
            dataSource={data}
            renderItem={(item, index) => {
              const data = this.state.videoData.filter(
                (v) => v.videoName === item.name
              )[0];

              let videoName = item.name;
              let videoDesc = "Mô Tả";
              if (data && data.data) {
                videoName = data.data.videoName || videoName;
                videoDesc = data.data.videoDesc || videoDesc;
              }

              const thumbnailData = this.state.videoThumbnailList.filter(
                (v) => {
                  console.log(v.name);
                  return v.name === formatVideoName(item.name);
                }
              )[0];

              let thumbUrl = null;
              if (thumbnailData) {
                console.log("Has thumb", thumbnailData);
                thumbUrl = thumbnailData.url;
              }

              return (
                <List.Item>
                  {thumbUrl ? (
                    <div
                      className="video-item-list"
                      style={{
                        backgroundImage: `url(${thumbUrl})`,
                        backgroundSize: "cover",
                      }}
                      onClick={() =>
                        this.openFileView(item.location, item.name)
                      }
                    />
                  ) : (
                    <div
                      className="video-item-list"
                      onClick={() =>
                        this.openFileView(item.location, item.name)
                      }
                    >
                      {item.name}
                    </div>
                  )}

                  <div className="video-item-list-desc">
                    <div>
                      <strong>{videoName}</strong>
                      <Button
                        shape="circle"
                        icon={<EditOutlined />}
                        style={{ marginLeft: "10px" }}
                        onClick={() => this.showVideoEdit(index)}
                      />
                    </div>
                    <span>{videoDesc}</span>
                  </div>
                  {this.state.currentEditVideoIndex === index && (
                    <div className="video-item-list-desc-editor">
                      <Input
                        placeholder={videoName}
                        value={this.state.currentEditVideoName}
                        onChange={(ev) => this.onVideoDescEdit(true, ev)}
                      />
                      <Input
                        placeholder={videoDesc}
                        value={this.state.currentEditVideoDesc}
                        onChange={(ev) => this.onVideoDescEdit(false, ev)}
                      />
                      <Button onClick={() => this.saveVideoDesc(item)}>
                        Lưu
                      </Button>
                    </div>
                  )}
                  {/* } */}
                </List.Item>
              );
            }}
          />
          ,
        </div>
      </div>
    );
  };

  onPostContentChange = (value) => {
    this.setState({ postContent: value });
    // const content = JSON.stringify(value.toJSON())
  };

  uploadPost() {
    const ref = this.state.currentSelectTree[0].key;
    uploadPostToFirebase(
      ref,
      this.state.postLang,
      this.state.postTitle,
      this.state.postDes,
      this.state.postContent.toString("html")
    );
    this.setState({ isUploadPostModalVisible: false });
  }

  renderUploadPostModal() {
    return (
      <Modal
        title="Tạo thư mục"
        visible={this.state.isUploadPostModalVisible}
        onOk={() => this.uploadPost()}
        onCancel={() => {
          this.setState({ isUploadPostModalVisible: false });
        }}
      >
        <Select
          defaultValue="vn"
          onSelect={(ev) => this.setState({ postLang: ev })}
        >
          <Option value="vn">Tiếng Việt</Option>
          <Option value="en">English</Option>
          <Option value="cn">Chinese</Option>
        </Select>
        <Input
          placeholder="Tiêu đề"
          onChange={(ev) => {
            this.setState({ postTitle: ev.target.value });
          }}
        />
        <Input
          placeholder="Mô tả"
          onChange={(ev) => {
            this.setState({ postDes: ev.target.value });
          }}
        />
        <CustomRichTextEditor
          value={this.state.postContent}
          onChange={this.onPostContentChange}
        />
      </Modal>
    );
  }

  setCurrentViewPostContent = (lang) => {
    let post = this.state.currentViewPostData.list.find((p) => p.lang == lang);
    if (!post) {
      post = {
        title: this.state.currentViewPostData.title,
        des: this.state.currentViewPostData.des,
        lang,
        content: initialValue.toString("html"),
      };
    }
    this.setState({
      // currentViewPost: post,
      currentViewPostLang: lang,
      currentViewPostContent: RichTextEditor.createValueFromString(
        post.content,
        "html"
      ),
    });
  };

  onPostViewContentChange = (value) => {
    this.setState({
      currentViewPostContent: value,
    });
  };

  editPost = () => {
    const ref = this.state.currentSelectTree[0].key;
    this.setState({ isViewPostVisible: false });
    editPostToFirebase(
      ref,
      this.state.currentViewPostData.key,
      this.state.currentViewPostLang,
      this.state.currentViewPostData.title,
      this.state.currentViewPostData.des,
      this.state.currentViewPostContent.toString("html")
    );
  };

  renderViewPostModal() {
    const originalContent = this.state.currentViewPost
      ? RichTextEditor.createValueFromString(
          this.state.currentViewPost.content,
          "html"
        )
      : null;
    const content = this.state.currentViewPostContent
      ? this.state.currentViewPostContent
      : initialValue;
    const title = this.state.currentViewPost
      ? this.state.currentViewPost.title
      : "";
    const des = this.state.currentViewPost
      ? this.state.currentViewPost.des
      : "";
    console.log(content, this.state.currentViewPostLang);
    return (
      <Modal
        title="Tạo thư mục"
        visible={this.state.isViewPostVisible}
        onOk={() => this.editPost()}
        onCancel={() => {
          this.setState({ isViewPostVisible: false });
        }}
        width={"90vw"}
      >
        <Select
          value={this.state.currentViewPostLang}
          onSelect={(ev) => this.setCurrentViewPostContent(ev)}
          style={{ width: "50%" }}
        >
          <Option value="vn">Tiếng Việt</Option>
          <Option value="en">English</Option>
          <Option value="cn">Chinese</Option>
        </Select>
        <Input value={title} />
        <Input value={des} />
        <div className="post-content-edit">
          {originalContent && <CustomRichTextEditor value={originalContent} />}
          <CustomRichTextEditor
            value={content}
            subClass="post-translate-editor"
            onChange={this.onPostViewContentChange}
          />
        </div>
      </Modal>
    );
  }

  openViewPostModal = (post) => {
    this.setState({
      currentViewPostData: post,
      currentViewPost: post.list[0],
      currentViewPostLang: post.list[0].lang,
      currentViewPostContent: RichTextEditor.createValueFromString(
        post.list[0].content,
        "html"
      ),
      isViewPostVisible: true,
    });
  };

  confirmDeletePost(key) {
    const ref = this.state.currentSelectTree[0].key;
    deletePost(ref, key);
  }

  renderPostList() {
    const data = this.state.postList.filter((post) => {
      let isPostContainKey = false;
      console.log(post.list);
      isPostContainKey = post.list.find(
        (p) =>
          p.title.includes(this.state.searchKey) ||
          p.content.includes(this.state.searchKey) ||
          p.des.includes(this.state.searchKey)
      )
        ? true
        : false;
      return isPostContainKey;
    });
    console.log(data);
    return (
      <List
        grid={{
          gutter: 16,
          xs: 1,
          sm: 2,
          md: 1,
          lg: 1,
          xl: 1,
          xxl: 1,
        }}
        dataSource={data}
        renderItem={(item) => (
          <List.Item key={item.key} style={{ marginBottom: "20px" }}>
            <div className="post-item-container">
              <Card
                title={item.title}
                onClick={() => this.openViewPostModal(item)}
                className="post-item"
                bordered={false}
              >
                {/* <RichTextEditor readOnly={true} value={RichTextEditor.createValueFromString(item.content, 'html')}>
              </RichTextEditor> */}
                <img className="post-img" />
                <div className="post-content-review">{item.des}</div>
              </Card>
              <div className="post-item-action">
                <Popconfirm
                  placement="topLeft"
                  title={"Xóa bài đăng"}
                  onConfirm={() => this.confirmDeletePost(item.key)}
                  okText="Xóa"
                  cancelText="Hủy"
                >
                  <DeleteOutlined />
                </Popconfirm>
              </div>
            </div>
          </List.Item>
        )}
      />
    );
  }

  renderPostUpload() {
    return (
      <div>
        {this.renderUploadPostModal()}
        {this.renderViewPostModal()}
        <Button
          onClick={() => {
            this.setState({ isUploadPostModalVisible: true });
          }}
        >
          Tạo bài đăng
        </Button>
        {this.renderPostList()}
      </div>
    );
  }

  renderImageUpload() {
    const data = this.state.imageList;
    return (
      <div className="video-upload-section">
        <Upload beforeUpload={this.beforeUploadImage}>
          <Button>
            <UploadOutlined /> Click to Upload
          </Button>
        </Upload>
        <div className="video-list">
          <List
            grid={{ gutter: 4, column: 3 }}
            dataSource={data}
            renderItem={(item) => (
              <List.Item onClick={() => this.openFileView(item.location)}>
                <div className="video-item-list">
                  <img
                    src={item.url ? item.url : ""}
                    style={{
                      objectFit: "cover",
                      width: "100%",
                      height: "100%",
                    }}
                  />
                </div>
              </List.Item>
            )}
          />
          ,
        </div>
      </div>
    );
  }

  renderFolderSection = () => {
    return (
      <div className="upload-section-folder">
        {/* <div style={{ width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}> */}
        <div>
          {/* {
            this.state.treeStack.length > 1 &&
            <ArrowLeftOutlined onClick={this.backToPreviosFolder} />
          } */}
          {/* <Button style={{ width: "50%", marginLeft: '20px' }} block onClick={() => { this.setState({ isFolderCreateModalVisible: true }) }}>
            Tạo thư mục mới
        </Button> */}
          <Select
            defaultValue="folder"
            onSelect={(ev) => this.setState({ currentPage: ev })}
          >
            <Option value="folder">Thư mục</Option>
            <Option value="image">Hình ảnh</Option>
            <Option value="video">Video</Option>
            <Option value="post">Đăng bài</Option>
          </Select>
        </div>

        <div style={{ width: "100%" }}>
          {this.state.currentPage == "folder" && this.renderFolderTree()}
          {this.state.currentPage == "video" && this.renderVideoUpload()}
          {this.state.currentPage == "image" && this.renderImageUpload()}
          {this.state.currentPage == "post" && this.renderPostUpload()}
        </div>
      </div>
    );
  };

  renderCreateModal = () => {
    return (
      <Modal
        title="Tạo thư mục"
        visible={this.state.isFolderCreateModalVisible}
        onOk={this.createFolder}
        onCancel={this.closeFolderCreateModal}
      >
        <Input
          value={this.state.createFolderName}
          onChange={(ev) =>
            this.setState({ createFolderName: ev.target.value })
          }
        />
      </Modal>
    );
  };

  handleAutoSaveChanges = (newAutoSaveData) => {
    console.log("handleAutoSaveChanges", newAutoSaveData);
    const { data, ext } = newAutoSaveData;
    this.setState({ autoSaveData: data, autoSaveExtension: ext });
    // Saving to local storage
  };

  renderViewFileModal = () => {
    return (
      <Modal
        title="File"
        visible={this.state.isViewFileModalVisible}
        onOk={() => this.setState({ isViewFileModalVisible: false })}
        onCancel={() => this.setState({ isViewFileModalVisible: false })}
        width="90vw"
        destroyOnClose={true}
      >
        <div>
          {this.state.currentPage == "video" && (
            <div>
              <VideoContainer
                videoUrl={this.state.currentFileUrl}
                videoName={this.state.currentFileName}
              />
            </div>
          )}
          {this.state.currentPage == "image" && (
            <img src={this.state.currentFileUrl} className="file-reviewer" />
          )}
        </div>
      </Modal>
    );
  };

  searchForPost = (value) => {
    this.setState({ searchKey: value });
  };

  render() {
    return (
      <div>
        {this.renderCreateModal()}
        {this.renderViewFileModal()}
        <div className="header">
          <Avatar size={60} style={{ marginRight: "50px" }} />
          <Typography.Title style={{ marginLeft: "20px" }} level={3}>
            Ngày mai tươi sáng
          </Typography.Title>
          <SettingFilled style={{ fontSize: 35, marginLeft: "20px" }} />
        </div>

        <div className="upload-section">
          <ArrowLeftOutlined style={{ fontSize: 35, marginRight: "20px" }} />
          <Input.Search
            placeholder="Tìm kiếm"
            onSearch={(value) => this.searchForPost(value)}
            onChange={(e) => this.searchForPost(e.target.value)}
            style={{ width: '75%' }}
          />
        </div>
        <div className="upload-section">
          <Input
            style={{ width: '75%' }}
            addonAfter="0kb"
            value={this.state.currentSelectTree[0].title}
            disabled={true}
          />
        </div>

        <div className="upload-section">
          {/* <div className="upload-section-feature">
            <Button type="link">Tạo mới</Button>
            <Button type="link">Hình ảnh</Button>
            <Button type="link">Video</Button>
            <Button type="link">Mp3</Button>
            <Button type="link">Đăng bài</Button>
            <Button type="link">Bình luận</Button>
          </div> */}
          {this.renderFolderSection()}
          <div className="upload-section-category">
            {/* <Button type="link">Chia sẻ</Button>
            <div className="upload-section-category-group">
              <Button type="link">Tin tức</Button>
              <Button type="link">Phim ảnh</Button>
              <Button type="link">Ca nhạc</Button>
              <Button type="link">Báo chí</Button>
              <ShareAltOutlined style={{ fontSize: '50px' }} />
            </div> */}
            <Select placeholder="Chia sẻ">
              <Option value="news">Tin tức</Option>
              <Option value="media">Phim ảnh</Option>
              <Option value="music">Ca nhạc</Option>
              <Option value="post">Báo chí</Option>
            </Select>
          </div>
        </div>
        <div className="upload-footer">
          <Button
            type="link"
            onClick={() => this.setState({ isFolderCreateModalVisible: true })}
          >
            Sanh cấp {this.state.currentSelectTree[0].level}+
          </Button>
          <Button type="link">Tất cả</Button>
          <Button type="link">Lượng hiển thị 3*</Button>
        </div>
        {this.state.isLoading && (
          <div className="loading">
            <Spin />
          </div>
        )}
      </div>
    );
  }
}
